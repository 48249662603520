import React, { useState, useRef } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { MentionsInput, Mention } from "react-mentions";
import { AttachmentIcon } from "../../../../assets/AjxIcons";
import supabase from "../../../Supabase";
import NotifierHelper from "../../../../application/common/NotifierHelper";
import {
  SUPABASE_TABLE_NAME,
  SUPABASE_BUCKET_NAME,
} from "../../../Supabase/constants";
import { addNewComment } from "../../../../store/reducers/commentSlice";
import { ReactComponent as CrossIcon } from "../../../../assets/images/icons-mlp/x-dismiss.svg";
import { imageDetector } from "../../../../application/utils/imageDetector";
import FileContainer from "../../FileContainer";
import { nanoid } from "nanoid";
import { GlobalHotKeys } from "react-hotkeys";
import { getDefaultObject } from "./utils";
import { AnimatePresence, motion } from "framer-motion";
import { DEFAULT_AVATAR } from "../../../../constants";

const PostPill = ({ projectId }) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);
  const members = useSelector((state) => state.members.workspace);
  const workSpaceInfo = useSelector((state) => state.user.workSpaceInfo);
  const projects = useSelector((state) => state.project.projects);
  const defaultProject = useSelector((state) => state.project.defaultProject);

  const [message, setMessage] = useState("");
  const [isSendMessageLoading, setIsSendMessageLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const currentProject = projects?.filter(
    (project) => project.id === projectId
  )[0];

  const handleUploadFile = async () => {
    if (!file) return null;

    const { data, error } = await supabase.storage
      .from(SUPABASE_BUCKET_NAME.COMMENT_FILES)
      .upload(
        `${nanoid(7)}-post.${file.name.substring(
          file.name.lastIndexOf(".") + 1
        )}`,
        file,
        {
          cacheControl: "3600",
          upsert: true,
        }
      );

    if (error) throw new Error(error);

    const { data: downloadURL } = supabase.storage
      .from(SUPABASE_BUCKET_NAME.COMMENT_FILES)
      .getPublicUrl(data.path);

    return downloadURL.publicUrl;
  };

  const handleOnSendMessage = async () => {
    if (message === "") return;
    try {
      setIsSendMessageLoading(true);

      const mentionUserIds =
        message
          .match(/user:([a-f0-9-]+)/g)
          ?.map((match) => match.split(":")[1]) ?? [];
      const fileLink = await handleUploadFile();

      const initialData = getDefaultObject({
        fileLink,
        mentionUserIds,
        message,
        userInfo,
        workSpaceInfo,
        currentProject: currentProject || defaultProject,
      });

      setMessage("");
      dispatch(addNewComment(initialData));
      setIsSendMessageLoading(false);
      setImagePreview(null);
      setFile(null);

      const { error } = await supabase
        .from(SUPABASE_TABLE_NAME.COMMENTS)
        .insert({
          created_by: userInfo.id,
          body: message,
          mentioned_users_ids: mentionUserIds,
          workspace_id: workSpaceInfo.id,
          file_link: fileLink,
          project_id: projectId ?? defaultProject.id,
        });

      if (error) throw new Error(error);
    } catch (error) {
      NotifierHelper.notify("info", error.message);
    }
  };

  const handleOnFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Display image preview
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setImagePreview(null);
    }
  };

  const handleOnInputAction = (e) => {
    if (e.key === "Enter" && e.metaKey) {
      e.preventDefault();
      handleOnSendMessage();
    }
    if (e.key === "Escape") {
      inputRef.current.blur();
    }
  };

  const keyMap = {
    postComment: "p",
  };

  const keyHandlers = {
    postComment: () => {
      // inputRef.current.value = "";
      setTimeout(() => {
        inputRef.current.focus();
      });
    },
  };

  return (
    <div className="post-pill-main-container">
      <div className="post-pill-box">
        <div className="left-side">
          <div className="input-content">
            <img
              src={userInfo?.avatar ?? DEFAULT_AVATAR}
              alt="User Profile"
              className="profile-img"
            />
            <MentionsInput
              placeholder="What's happening?"
              inputRef={inputRef}
              id="user-input"
              className="input-tag cleanbtn"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleOnInputAction}
            >
              <Mention
                trigger={"@"}
                markup="@[__display__](user:__id__)"
                data={members.map((member) => ({
                  display: member.username,
                  id: member.user_id,
                }))}
                renderSuggestion={(highlightedDisplay, focused) => (
                  <div key={highlightedDisplay.id}>
                    {highlightedDisplay.display}
                  </div>
                )}
              />
            </MentionsInput>
            <div className="input-content-actions">
              <div className="attachment-icon">
                <label htmlFor="file-upload">
                  <AttachmentIcon />
                </label>
                <input
                  type="file"
                  accept="image/*, .pdf"
                  onChange={handleOnFileChange}
                  onClick={(event) => (event.target.value = null)}
                  id="file-upload"
                />
              </div>
              <button
                disabled={isSendMessageLoading}
                className={`post-btn cleanbtn anim ${
                  message.length > 0 ? "active" : "disabled"
                }`}
                onClick={handleOnSendMessage}
              >
                Post
              </button>
            </div>
          </div>
          <AnimatePresence>
            {imagePreview && imageDetector(file?.name) ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.3 }}
              >
                <div className="image-preview-container">
                  <CrossIcon
                    className="cross-icon"
                    onClick={() => {
                      setFile(null);
                      setImagePreview(null);
                    }}
                  />
                  {imagePreview && (
                    <img src={imagePreview} alt="Post Preview" />
                  )}
                </div>
              </motion.div>
            ) : file?.name ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
              >
                <FileContainer
                  fileName={file.name}
                  isRemovable
                  className="file-container"
                  onRemoveClick={() => {
                    setFile(null);
                    setImagePreview(null);
                  }}
                />
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>
      </div>

      <GlobalHotKeys keyMap={keyMap} handlers={keyHandlers} allowChanges />
    </div>
  );
};

export default PostPill;
