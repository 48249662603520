import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { reduce, groupBy, sumBy, toInteger, omit } from "lodash";
import { GlobalHotKeys } from "react-hotkeys";

import SessionsApi from "../../infrastructure/ApiServices/SessionsApi";
import NotifierHelper from "../../application/common/NotifierHelper";
import DeleteConfirmation from "../../infrastructure/Common/DeleteConfirmation";
import secondsToTimestamp from "../../application/utils/secondsToTimestamp";
import TaskCard from "../../infrastructure/Common/TaskCard";
import StatusTitle from "./StatusTitle";

import "./sessions.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  handleDeleteTask,
  handleUpdateTask,
  // setFilterBy,
} from "../../store/reducers/projectSlice";
import { useKBar } from "kbar";
import supabase from "../../infrastructure/Supabase";
import { SUPABASE_TABLE_NAME } from "../../infrastructure/Supabase/constants";
// import FilterMenu from "../../infrastructure/Common/FilterMenu";

const Sessions = () => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.user.userInfo);
  const tasks = useSelector((state) => state.project.tasks);
  const filterBy = useSelector((state) => state.project.filterBy);
  const workflowStatutes = useSelector(
    (state) => state.dropdown.workflowStatus
  );
  const dispatch = useDispatch();

  const { query } = useKBar();

  const [greetings, setGreetings] = useState("");
  const [selfDetail, setSelfDetail] = useState({});

  const [modal, setModal] = useState("");
  const [modalData, setModalData] = useState([]);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [focus, setFocus] = useState(-1);
  const taskOrder = workflowStatutes.map((workflow) =>
    workflow.name.toLowerCase()
  );

  const groupedSession = useMemo(() => {
    let filteredTasks = [];

    const workLists = tasks ? [...tasks] : [];
    // While selecting task from keyboard with arrow key its randomly selecting any task.
    // To overcome that problem.

    // First group task by its status
    const grouped = groupBy(workLists, "status");

    // Now using one another array to have exact a same task order as per our UI.
    const orderedArray = [];
    // Looping through that grouped object and pushed that all task in that orderArray.
    taskOrder.forEach((key) => {
      if (grouped[key]) orderedArray.push(...grouped[key]);
    });

    // Now we making the final object according to its status
    // Cause we're using this index to move through task with keyboard and to maintain the exact same order as per UI
    // After that making final object according to status
    filteredTasks = groupBy(
      orderedArray.map((item, i) => ({ ...item, index: i })),
      "status"
    );

    if (filterBy && filterBy?.value !== "all") {
      // Filter by me
      if (filterBy.value === "me") {
        filteredTasks = groupBy(
          orderedArray
            .filter((task) => task.assignee === userInfo.id)
            .map((item, i) => ({ ...item, index: i })),
          "status"
        );
      }

      if (filterBy?.parent === "Assigned to") {
        filteredTasks = groupBy(
          orderedArray
            .filter((task) => task.assignee === filterBy.value)
            .map((item, i) => ({ ...item, index: i })),
          "status"
        );
      }

      // Sort by status
      if (
        filterBy.value === "to do" ||
        filterBy.value === "in progress" ||
        filterBy.value === "completed" ||
        filterBy.value === "backlog"
      ) {
        if (filterBy.statusId) {
          filteredTasks = {
            [filterBy.value]:
              filteredTasks[filterBy.value]?.filter(
                (task) => task.status_id === filterBy.statusId
              ) || [],
          };
        } else {
          filteredTasks = {
            [filterBy.value]: filteredTasks[filterBy.value] || [],
          };
        }
      }

      if (
        filteredTasks.length === 0 ||
        Object.keys(filteredTasks).length === 0
      ) {
        filteredTasks = {
          "in progress": [],
          "to do": [],
          completed: [],
          backlog: [],
        };
      }
    }

    return filteredTasks;
  }, [tasks, filterBy, taskOrder]);

  const keyMap = {
    // addSession: "t",
    arrowUp: "up",
    arrowDown: "down",
    goBack: "esc",
  };

  const keyHandlers = {
    addSession: (e) => {
      navigate("/canvases/new");
      e.preventDefault();
      return false;
    },

    arrowUp: (e) => {
      handleSessionFocus(e, "up");
      e.preventDefault();
      return false;
    },

    arrowDown: (e) => {
      handleSessionFocus(e, "down");
      e.preventDefault();
      return false;
    },

    goBack: (e) => {
      if (focus > -1) document.querySelector("#session_card_" + focus).blur();
      setFocus(-1);
      e.preventDefault();
      return false;
    },
  };

  useEffect(() => {
    const time = new Date().getHours();
    if (time < 12) {
      setGreetings("Good morning");
    } else if (time < 17) {
      setGreetings("Good afternoon");
    } else {
      setGreetings("Good evening");
    }

    if (localStorage.getItem("login_user") !== "undefined") {
      const self = localStorage.getItem("login_user");
      setSelfDetail(JSON.parse(self));
    }
  }, []);

  const handleModal = (name, item = {}, index = null) => {
    setModal(name);
    setModalData({ ...item, index });
  };

  const handleDelete = (confirm, data) => {
    dispatch(handleDeleteTask(data));
    setModal("");

    SessionsApi.deleteSession(data._id)
      .then((res) => {
        NotifierHelper.notify("delete", res.data.result);
      })
      .catch((err) => {
        console.log(err);
        NotifierHelper.notify(
          "info",
          "something went wrong, please try again later"
        );
        setDeleteLoader(false);
      });
  };

  const taskIntervals = (session) => {
    const intervals = reduce(
      session.tasks,
      (result, value) => {
        return result.concat(value.timeIntervals);
      },
      []
    );
    return intervals;
  };

  const handleSessionFocus = (e, action) => {
    const workLists = [];
    if (action === "down" || action === "up") {
      taskOrder.forEach((key) => {
        if (groupedSession[key]) workLists.push(...groupedSession[key]);
      });
    }

    if (action === "down") {
      if (focus < workLists.length - 1) {
        document.querySelector("#session_card_" + (focus + 1)).focus();
        setFocus(focus + 1);
      } else {
        setFocus(0);
        document.querySelector("#session_card_0").focus();
      }
    }

    if (action === "up") {
      if (focus > 0) {
        setFocus(focus - 1);
        document.querySelector("#session_card_" + (focus - 1)).focus();
      } else {
        setFocus(workLists.length - 1);
        document
          .querySelector("#session_card_" + (workLists.length - 1))
          .focus();
      }
    }

    if (action === "hover" && e.target.id.includes("session_card_")) {
      var index = e.target.id.lastIndexOf("_");
      var result = e.target.id.substr(index + 1);
      setFocus(toInteger(result));
      document.querySelector("#session_card_" + result).focus();
    }
  };

  const handleChange = (e, action = null, session = null) => {
    const { checked } = e.target;
    if (action === "main-checkbox") {
      const updatedSession = {
        ...session,
        status: checked ? "completed" : "todo",
      };
      setTimeout(() => {
        onSaveSession(updatedSession);
      });
    } else {
      // setProject({ ...project, [name]: value });
    }
  };

  const onSaveSession = async (newSession) => {
    if (!newSession.id) {
      return;
    }
    const session = omit(newSession, "index");
    const { error } = await supabase
      .from(SUPABASE_TABLE_NAME.TASK)
      .update(session)
      .eq("id", session.id);

    if (error) {
      NotifierHelper.notify("info", error.message);
    } else {
      dispatch(handleUpdateTask(newSession));
    }
  };

  const handleDateChange = (date, type, session) => {
    const isoFormatDate = date ? new Date(date).toISOString() : null;
    switch (type) {
      case "milestone":
        const updatedSession = { ...session, dueDate: isoFormatDate };
        onSaveSession(updatedSession);
        break;
      default:
        break;
    }
  };

  const handleSelectChange = (event, type, session) => {
    const updatedSession = { ...session, assignee: event.value };

    setTimeout(() => {
      onSaveSession(updatedSession);
    });
  };

  return (
    <div className="container-fluid mt-4 pt-2 px-0">
      <div className="row">
        <div className="col-12 ">
          <div className="content-header d-flex align-items-start justify-content-between px-3">
            <div className="meta-container d-flex align-items-center gap-2">
              <h1 className="fs-4 fw-semi mt-1">Canvases</h1>
              {/* <FilterMenu onSelect={(e) => dispatch(setFilterBy(e))} /> */}
            </div>
            <div className="action-container d-flex align-items-center">
              <div className="action-item">
                <button
                  className="mlp-btn large no-wrap"
                  onClick={() => {
                    query.setCurrentRootAction("canvas");
                    query.toggle();
                  }}
                >
                  + New Canvas
                  <span className="mlp-shortcut-tag ml">C</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="content-tasks position-relative pb-5">
        {/* {typeof groupedSession === "object" &&
          Object.keys(groupedSession).length > 0 &&
          taskOrder.map((k) => (
            <div key={k}>
              {groupedSession[k] && (
                <StatusTitle
                  status={k}
                  data={groupedSession[k]}
                  // handleChange={handleChange}
                  totalTimeTracked={secondsToTimestamp(
                    sumBy(groupedSession[k], (session) =>
                      reduce(
                        taskIntervals(session),
                        (total, interval) =>
                          total + parseInt(interval.duration),
                        0
                      )
                    )
                  )}
                />
              )}

              {groupedSession[k] &&
                groupedSession[k].length > 0 &&
                groupedSession[k].map((item, index) => (
                  <TaskCard
                    key={item.id}
                    tIndex={index}
                    session={item}
                    handleModal={handleModal}
                    handleSessionFocus={handleSessionFocus}
                    isTask={true}
                    handleChange={handleChange}
                    handleDateChange={handleDateChange}
                    handleSelectChange={handleSelectChange}
                  />
                ))}

              {groupedSession[k] && groupedSession[k].length === 0 && (
                <div className="text-second px-3 mb-2 text-center">
                  Looks like there's no task(s) with the selected filter.
                </div>
              )}
            </div>
          ))} */}

        {tasks.length > 0 &&
          tasks.map((item, index) => (
            <TaskCard
              key={item.id}
              index={index}
              session={item}
              handleModal={handleModal}
              handleSessionFocus={handleSessionFocus}
              isTask={true}
              handleChange={handleChange}
              handleDateChange={handleDateChange}
              handleSelectChange={handleSelectChange}
            />
          ))}

        {(!tasks || tasks.length === 0) && (
          <div className="text-second px-3">
            Let’s get started! press <span className="mlp-shortcut-tag">T</span>{" "}
            to create your first task.
          </div>
        )}
      </section>

      <GlobalHotKeys
        keyMap={keyMap}
        handlers={keyHandlers}
        allowChanges={true}
      ></GlobalHotKeys>
      {modal && (
        <DeleteConfirmation
          setModal={setModal}
          modal={modal === "delete-session"}
          data={modalData}
          onDeleteSuccess={handleDelete}
          modalLoader={deleteLoader}
        />
      )}
    </div>
  );
};

export default Sessions;
