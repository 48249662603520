"use client";

const Card = ({ item }) => {
  return (
    <div className="how-it-works-card">
      <div className="mb-3">
        <div className="d-flex m-0 p-0">
          <p className="card-number">{item.id}</p>
          <div>
            <p className="m-0 p-0 card-heading">{item.title}</p>
            <p className="m-0 p-0 card-subheading">{item.subtitle}</p>
          </div>
        </div>
      </div>
      <div className="card-image-container">
        <img src={item.image} className="card-image" alt="how it works" />
      </div>
    </div>
  );
};
export default Card;
