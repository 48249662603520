import { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import "./userSidebar.scss";
import NotifierHelper from "../../application/common/NotifierHelper";
import supabase from "../../infrastructure/Supabase";
import { resetUserState } from "../../store/reducers/userSlice";
import { resetSessionState } from "../../store/reducers/sessionSlice";
import { resetProjectState } from "../../store/reducers/projectSlice";
import { setDropDown } from "../../store/reducers/dropDownSlice";
import { resetMemberState } from "../../store/reducers/membersSlice";
import { resetCommentState } from "../../store/reducers/commentSlice";
import ContextMenu from "../../infrastructure/Common/ContextMenu";
import ArrowDown from "../../assets/svgs/ArrowDown";
import { useKBar } from "kbar";
import WebContext from "../../application/contexts/WebContext";
import profile_placeholder from "./../../assets/images/icons/logo.svg";

const UserSidebar = () => {
  // const [paymentLink, setPaymentLink] = useState("");
  const [customer, setCustomer] = useState({});
  const userInfo = useSelector((state) => state.user.userInfo);
  const projects = useSelector((state) => state.project.projects);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();

    if (error) {
      NotifierHelper.notify("info", error.message);
    }

    supabase.removeAllChannels();
    dispatch(resetUserState());
    dispatch(resetSessionState());
    dispatch(resetProjectState());
    dispatch(setDropDown({}));
    dispatch(resetMemberState());
    dispatch(resetCommentState());
    window.analytics.reset();
    NotifierHelper.notify("logout", "Logout successfully!");
    localStorage.removeItem("auth_token");
    navigate("/login");
  };

  const initialContextMenu = {
    show: false,
    x: 0,
    y: 0,
  };

  const [contextMenu, setContextMenu] = useState(initialContextMenu);

  const handleContextMenu = (e) => {
    e.preventDefault();

    const { clientX, clientY } = e;

    setContextMenu({
      show: true,
      x: clientX - 20,
      y: clientY - 40,
    });
  };

  const { actions } = useContext(WebContext);

  const { query } = useKBar();

  useEffect(() => {
    const fetchCustomerData = async () => {
      const { data: customerData } = await supabase
        .from("customers")
        .select("*")
        .eq("user_id", userInfo.id);
      setCustomer(customerData[0]);
    };

    fetchCustomerData();
  }, [userInfo.id]);

  useEffect(() => {
    query?.registerActions(actions);
    window.createLemonSqueezy();

    // const fetchPaymentLink = async () => {
    //   try {
    //     const response = await axios.post(
    //       `${process.env.REACT_APP_PAYMENT_BACKEND_URL}/api/payment`,
    //       {
    //         productId: "399594",
    //         userId: userInfo.id,
    //         userEmail: userInfo.email,
    //         userName: userInfo.username,
    //       }
    //     );

    //     const data = response.data;

    //     setPaymentLink(data.checkoutUrl);

    //     // LemonSqueezy.Url.Open(data.checkoutUrl);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // };

    // fetchPaymentLink();
  }, [actions]);

  return (
    <div className="user-sidebar">
      <div className="user-dropdown-group">
        <button
          className="user-dropdown-trigger"
          onContextMenu={handleContextMenu}
          onClick={handleContextMenu}
        >
          <img
            src={userInfo.avatar ?? profile_placeholder}
            alt={`${userInfo.username} Avatar`}
            className="user-avatar"
          />
        </button>

        <AnimatePresence>
          {contextMenu.show && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              exit={{ opacity: 0 }}
            >
              <ContextMenu
                x={contextMenu.x}
                y={contextMenu.y}
                onClose={() => setContextMenu(initialContextMenu)}
                buttons={[
                  {
                    text: "Log Out",
                    onClick: handleLogout,
                  },
                ]}
              />
            </motion.div>
          )}
        </AnimatePresence>

        <div className="sidebar-menu">
          <div className="menu-item">
            <NavLink to="/library">
              <span>Library</span>
            </NavLink>
          </div>
          <div className="menu-item">
            <NavLink to="/settings">
              <span>Settings</span>
            </NavLink>
          </div>
        </div>
      </div>

      <div className="footer">
        {/* {!!(customer?.ls_customer_id || customer?.ls_subscription_id) ? (
          <p>
            Coil <span className="tier-badge pro-badge">Pro</span>
          </p>
        ) : (
          <>
            <p>
              Coil <span className="tier-badge">Free</span>
            </p>
            <p>{projects?.length ?? 0} out of 20 Coil's Created.</p>
          </>
        )} */}
        <div className="footer-links">
          {/* {!!(customer?.ls_customer_id || customer?.ls_subscription_id) ? (
            <>
              <a href={`${paymentLink}`} className="lemonsqueezy-button">
                Upgrade to Team <FaArrowRight />
              </a>
            </>
          ) : (
            <>
              <a href={`${paymentLink}`} className="lemonsqueezy-button">
                Upgrade to Pro <FaArrowRight />
              </a>
            </>
          )} */}
          <a href="mailto:team@lata.so" className="link">
            Need Help?
          </a>
          <a
            href="https://hdputkfgtkqnghkkeegq.supabase.co/storage/v1/object/public/coil_app/Coil.dmg"
            download
          >
            Download Coil for Mac
            <ArrowDown styles="arrow-down-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default UserSidebar;
