import { useRef, useState } from "react";
import { GlobalHotKeys } from "react-hotkeys";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveCommentId,
  updateCommentsReply,
} from "../../../../store/reducers/commentSlice";
import supabase from "../../../Supabase";
import { ReactComponent as Message } from "../../../../assets/images/icons-mlp/message-circle.svg";
import {
  SUPABASE_BUCKET_NAME,
  SUPABASE_TABLE_NAME,
} from "../../../Supabase/constants";
import { Mention, MentionsInput } from "react-mentions";
import { AttachmentIcon } from "../../../../assets/AjxIcons";
import NotifierHelper from "../../../../application/common/NotifierHelper";
import { nanoid } from "nanoid";
import { ReactComponent as CrossIcon } from "../../../../assets/images/icons-mlp/x-dismiss.svg";
import { imageDetector } from "../../../../application/utils/imageDetector";
import FileContainer from "../../FileContainer";
import { getDefaultReplyObject } from "../../TaskView/UpdatePill/utils";
import { useOnClickOutside } from "../../ContextMenu/hooks";

const defaultAvatar = "https://i.ibb.co/JFR6LyW/user.png";

export default function ReplyInput({ notification, placeholder }) {
  const [showReplyInput, setShowReplyInput] = useState(false);
  const userInfo = useSelector((state) => state.user.userInfo);
  const members = useSelector((state) => state.members.workspace);
  const workSpaceInfo = useSelector((state) => state.user.workSpaceInfo);
  const fileRef = useRef();
  const [replyText, setReplyText] = useState("");
  const [isReplySending, setIsReplySending] = useState(false);
  const dispatch = useDispatch();
  const [replyFile, setReplyFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // State for image preview

  const keyMap = {
    replyOnNotification: "r",
    unselect: "esc",
  };

  const keyHandlers = {
    replyOnNotification: () => {
      setShowReplyInput(true);
      setTimeout(() => document.getElementById("reply-input").focus());
    },
    unselect: () => {
      dispatch(setActiveCommentId(""));
    },
  };

  const resetState = () => {
    setReplyText("");
    setIsReplySending(false);
    setShowReplyInput(false);
    setImagePreview(null); // Reset image preview
    setReplyFile(null);
  };

  // Function to handle image preview
  const handleImagePreview = (file) => {
    console.log(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  // Function to handle file selection
  const handleOnFileChange = (e) => {
    console.log(e);
    if (e.target.files.length) {
      const file = e.target.files[0];
      setReplyFile(file);
      handleImagePreview(file);
    }
  };

  const handleUploadFile = async () => {
    if (!replyFile) return null;

    // Upload file to storage
    console.log(replyFile);
    const { data, error } = await supabase.storage
      .from(SUPABASE_BUCKET_NAME.COMMENT_FILES)
      .upload(
        `${nanoid(7)}-reply.${replyFile.name.substring(
          replyFile.name.lastIndexOf(".") + 1
        )}`,
        replyFile,
        {
          cacheControl: "3600",
          upsert: true,
        }
      );

    if (error) {
      NotifierHelper.notify("info", error.message);
      return null;
    }

    // Get downloadURL from storage for the same file
    const { data: downloadURL } = supabase.storage
      .from(SUPABASE_BUCKET_NAME.COMMENT_FILES)
      .getPublicUrl(data.path);

    return downloadURL.publicUrl;
  };

  const handleSendReply = async () => {
    if (!replyText) return;
    setReplyText("");
    setShowReplyInput(false);

    const mentionUserIds =
      replyText
        .match(/user:([a-f0-9-]+)/g)
        ?.map((match) => match.split(":")[1]) ?? [];

    const fileLink = await handleUploadFile();

    const initialData = getDefaultReplyObject({
      fileLink,
      mentionUserIds,
      replyText,
      userInfo,
      workSpaceInfo,
      notification,
    });

    dispatch(
      updateCommentsReply({
        commentId: notification.id,
        replies: initialData,
      })
    );

    const { error } = await supabase.from(SUPABASE_TABLE_NAME.REPLIES).insert({
      comment_id: notification.id,
      created_by: userInfo.id,
      body: replyText,
      mentioned_users_ids: mentionUserIds,
      workspace_id: workSpaceInfo.id,
      parent_comment_id: null,
      file_link: fileLink,
    });

    if (error) {
      NotifierHelper.notify("info", error.message);
      setIsReplySending(false);
      return;
    }

    resetState();
  };

  const handleOnInputAction = (e) => {
    if (e.key === "Enter" && e.metaKey) {
      e.preventDefault();
      handleSendReply();
    }
    if (e.key === "Escape") {
      fileRef.current.blur();
    }
  };

  const replyBoxRef = useRef(null);

  useOnClickOutside(replyBoxRef, () => {
    setTimeout(() => {
      setShowReplyInput(false);
    }, 100);
  });

  return (
    <div className="reply-box-container" ref={replyBoxRef}>
      {showReplyInput ? (
        <div className="post-pill-box">
          <div className="left-side">
            <img
              src={userInfo.avatar || defaultAvatar}
              alt="User Profile"
              className="profile-img"
            />
            <MentionsInput
              placeholder={placeholder ?? "What's happening?"}
              inputRef={fileRef}
              id="reply-input"
              className="input-tag cleanbtn"
              value={replyText}
              onChange={(e) => setReplyText(e.target.value)}
              onKeyDown={handleOnInputAction}
            >
              <Mention
                trigger={"@"}
                markup="@[__display__](user:__id__)"
                data={members.map((member) => ({
                  display: member.username,
                  id: member.user_id,
                }))}
                renderSuggestion={(highlightedDisplay, focused) => (
                  <div
                    className={`user ${focused ? "focused" : ""}`}
                    key={highlightedDisplay.id}
                  >
                    {highlightedDisplay.display}
                  </div>
                )}
              />
            </MentionsInput>
          </div>
          <div className="right-side">
            <div className="attach-icon">
              <label htmlFor="reply-file-select">
                <AttachmentIcon />
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleOnFileChange}
                onClick={(event) => (event.target.value = null)}
                id="file-upload"
              />
            </div>
            <button
              disabled={isReplySending}
              className={`post-btn cleanbtn anim ${
                replyText.length > 0 ? "active" : "not-active"
              }`}
              onClick={handleSendReply}
            >
              Post
            </button>
          </div>
        </div>
      ) : (
        <div
          className="reply-btn d-flex align-items-center gap-1"
          onClick={(e) => {
            e.stopPropagation();
            setShowReplyInput(true);
            dispatch(setActiveCommentId(notification.id));
            setTimeout(() => document.getElementById("reply-input")?.focus());
          }}
        >
          <Message />
        </div>
      )}
      <input
        className="display-none"
        id="reply-file-select"
        type="file"
        accept="image/*, .pdf"
        onClick={(event) => (event.target.value = null)}
        ref={fileRef}
        onChange={handleOnFileChange}
      />
      {imagePreview && imageDetector(replyFile.name) ? (
        <div className="image-preview-container">
          <CrossIcon
            className="cross-icon"
            onClick={() => {
              setReplyFile(null);
              setImagePreview(null);
            }}
          />
          {imagePreview && <img src={imagePreview} alt="Preview" />}
        </div>
      ) : replyFile?.name ? (
        <FileContainer
          fileName={replyFile.name}
          isRemovable
          onRemoveClick={() => {
            setReplyFile(null);
            setImagePreview(null);
          }}
        />
      ) : null}
      <GlobalHotKeys
        keyMap={keyMap}
        handlers={keyHandlers}
        allowChanges={true}
      />
    </div>
  );
}
