import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import ProjectCard from "../../infrastructure/Common/ProjectCard";
import { GlobalHotKeys } from "react-hotkeys";

import { useSelector } from "react-redux";
import { toInteger } from "lodash";
import { useKBar } from "kbar";
import Loader from "../../infrastructure/Common/MasterLoader";

const Projects = (props) => {
  const navigate = useNavigate();

  const projects = useSelector((state) => state.project.projects);
  const isProjectLoading = useSelector(
    (state) => state.project.isProjectLoading
  );
  const [focus, setFocus] = useState(-1);

  const { query } = useKBar();

  const [activeTab, setActiveTab] = useState("active");

  const projectTabs = [{ name: "active" }, { name: "archived" }];

  const keyMap = {
    // enterNotes: "/",
    // scAddMilestone: "shift+l",
    // addSession: "t",
    // addNewProject: "p",
    arrowUp: "up",
    arrowDown: "down",
    goBack: "esc",
  };

  const keyHandlers = {
    addNewProject: (e) => {
      navigate("/tags/new");
      e.preventDefault();
      return false;
    },
    arrowUp: (e) => {
      handleSessionFocus(e, "up");
      e.preventDefault();
      return false;
    },
    arrowDown: (e) => {
      handleSessionFocus(e, "down");
      e.preventDefault();
      return false;
    },
  };

  // useEffect(() => {
  //     if (projects === null) {
  //         getProjects();
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const getProjects = () => {
  //     dispatch(fetchAllProjects(userInfo?.workspace?._id))
  // };

  const handleSessionFocus = (e, action) => {
    const filterProjects = projects?.filter(
      (proj) => proj.status === activeTab
    );

    if (action === "down") {
      if (focus < filterProjects.length - 1) {
        setFocus(focus + 1);
        document.querySelector("#project_card_" + (focus + 1)).focus();
      } else {
        setFocus(0);
        document.querySelector("#project_card_0").focus();
      }
    }

    if (action === "up") {
      if (focus > 0) {
        setFocus(focus - 1);
        document.querySelector("#project_card_" + (focus - 1)).focus();
      } else {
        setFocus(filterProjects.length - 1);
        document
          .querySelector("#project_card_" + (filterProjects.length - 1))
          .focus();
      }
    }

    if (
      action === "hover" &&
      e.target.parentElement.id.includes("project_card_")
    ) {
      var index = e.target.parentElement.id.lastIndexOf("_");
      var result = e.target.parentElement.id.substr(index + 1);
      setFocus(toInteger(result));
      document.querySelector("#project_card_" + result).focus();
    }
  };

  return (
    <div className="container-fluid mt-4 pt-2 px-3 position-relative">
      {isProjectLoading && projects !== null && (
        <Loader style={{ height: "100vh" }} />
      )}
      <div className="row">
        <div className="col-12 ">
          <div className="content-header d-flex align-items-start justify-content-between">
            <div className="meta-container">
              <h1 className="fs-4 fw-semi mt-1">Tags</h1>
            </div>
            <div className="action-container d-flex align-items-center">
              <div className="action-item">
                <button
                  className="mlp-btn large no-wrap"
                  onClick={() => {
                    query.setCurrentRootAction("project");
                    query.toggle();
                  }}
                >
                  + New Tag
                  <span className="mlp-shortcut-tag ml">T</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="content-projects">
        <div className="row">
          <div className="col-12">
            <div className="mb-4">
              <div className="mikyal-tabs d-flex">
                {projectTabs.map((tab, index) => (
                  <div
                    className={
                      "tab-item fw-norm " +
                      (tab.name === activeTab ? "active fw-semi" : "")
                    }
                    key={"tab-" + (index + 1)}
                    onClick={() => setActiveTab(tab.name)}
                  >
                    {tab.name}
                  </div>
                ))}
              </div>
              <div className="tabs-content">
                {activeTab === "active" && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="list-projects">
                        <div className="items-container">
                          <div className="row">
                            {projects?.some((proj) => proj.status === "active")
                              ? projects
                                  ?.filter((proj) => proj.status === "active")
                                  .map((item, index) => {
                                    return (
                                      <div key={index} className="col-12 mb-3">
                                        <ProjectCard
                                          project={item}
                                          index={index}
                                          handleSessionFocus={
                                            handleSessionFocus
                                          }
                                        />
                                      </div>
                                    );
                                  })
                              : !isProjectLoading && (
                                  <div className="text-second">
                                    Let’s get started! press{" "}
                                    <span className="mlp-shortcut-tag">P</span>{" "}
                                    to create your first project.
                                  </div>
                                )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "archived" && (
                  <div className="row">
                    <div className="col-md-12">
                      <div className="list-projects">
                        <div className="items-container">
                          <div className="row">
                            {projects?.some(
                              (proj) => proj.status === "archived"
                            )
                              ? projects
                                  ?.filter((proj) => proj.status === "archived")
                                  .map((item, index) => {
                                    return (
                                      <div key={index} className="col-12 mb-3">
                                        <ProjectCard
                                          project={item}
                                          index={index}
                                          handleSessionFocus={
                                            handleSessionFocus
                                          }
                                        />
                                      </div>
                                    );
                                  })
                              : !isProjectLoading && (
                                  <div className="text-second">
                                    Where your archived projects live!.
                                  </div>
                                )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <GlobalHotKeys
        keyMap={keyMap}
        handlers={keyHandlers}
        allowChanges={true}
      ></GlobalHotKeys>
    </div>
  );
};

export default Projects;
