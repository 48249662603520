export const getDefaultObject = ({
  currentProject,
  mentionUserIds,
  fileLink,
  workSpaceInfo,
  userInfo,
  message,
}) => {
  const dateNow = new Date().toISOString();

  return {
    created_at: dateNow,
    createdAt: dateNow,
    created_by: userInfo.id,
    body: message,
    mentioned_users_ids: mentionUserIds,
    workspace_id: workSpaceInfo.id,
    file_link: fileLink,
    project_id: currentProject.id,
    user: {
      avatar: userInfo.avatar,
      id: userInfo.id,
      name: userInfo.user_metadata.username,
    },
    project: {
      // Emoji might be undefined for default project 'All'
      emoji: currentProject?.emoji,
      id: currentProject.id,
      title: currentProject.title,
    },
    seen_user_ids: [],
    task_id: null,
    replies: [],
    reply_count: 0,
  };
};

export const getDefaultReplyObject = ({
  notification,
  userInfo,
  replyText,
  mentionUserIds,
  workSpaceInfo,
  fileLink,
}) => {
  const dateNow = new Date().toISOString();

  return {
    created_by: userInfo.id,
    body: replyText,
    mentioned_users_ids: mentionUserIds,
    workspace_id: workSpaceInfo.id,
    parent_comment_id: null,
    file_link: fileLink,
    createdAt: dateNow,
    user: {
      avatar: userInfo.avatar,
      id: userInfo.id,
      name: userInfo.user_metadata.username,
    },
  };
};
