import { createSlice } from "@reduxjs/toolkit";

export const commentSlice = createSlice({
  name: "session",
  initialState: {
    comments: [],
    activeCommentId: "",
    limit: 10,
    offset: 0,
  },
  reducers: {
    setComments: (state, action) => {
      const newComments = Array.isArray(action.payload) ? action.payload : [];
      state.comments = [...state.comments, ...newComments];
      state.offset = state.offset + newComments.length;
    },
    addNewComment: (state, action) => {
      state.comments = [action?.payload, ...state?.comments];
    },
    addNewReplyToComment: (state, action) => {
      const newReply = action.payload;

      const newModifiedComments = state.comments.map((comment) => {
        if (comment.id === newReply.comment_id) {
          return {
            ...comment,
            replies: [
              newReply,
              ...comment.replies.filter((reply) => reply.id !== undefined),
            ],
          };
        }
        return comment;
      });

      state.comments = newModifiedComments;

      // state.comments = [newReply, ...replies];
      // const comments = state.comments.map((notification) => {
      //   if (notification.id === newReply.comment_id) {
      //     return {
      //       ...notification,
      //       reply_count: (notification?.reply_count || 0) + 1,
      //       replies:
      //         notification?.replies && notification?.replies?.length > 0
      //           ? [newReply, ...notification.replies]
      //           : [newReply],
      //     };
      //   }
      //   return notification;
      // });

      // state.comments = comments;
    },
    deleteComment: (state, action) => {
      const comments = state.comments.filter(
        (noti) => noti.id !== action.payload
      );
      state.comments = comments;
    },
    updateComment: (state, action) => {
      const newComment = action.payload;
      const updateComments = state.comments.map((comment) => {
        if (comment.id === newComment.id) {
          return newComment;
        }
        return comment;
      });

      state.comments = updateComments;
    },
    updateCommentsEmoji: (state, action) => {
      const newEmojiDetails = action.payload;

      const comments = state.comments.map((comment) => {
        if (
          comment.workspace_id === newEmojiDetails.workspace_id &&
          comment.project.id === newEmojiDetails.id
        ) {
          return {
            ...comment,
            project: { ...comment.project, emoji: newEmojiDetails.emoji },
          };
        }
        return comment;
      });

      state.comments = comments;
    },
    addRepliesToComment: (state, action) => {
      const commentId = action.payload.commentId;
      const newReplies = action.payload.replies;
      const comments = state.comments.map((comment) => {
        if (comment.id === commentId) {
          return { ...comment, replies: newReplies };
        }
        return comment;
      });

      state.comments = comments;
    },
    setActiveCommentId: (state, action) => {
      state.activeCommentId = action.payload;
    },
    resetCommentState: (state) => {
      state.comments = [];
      state.activeCommentId = "";
      state.limit = 10;
      state.offset = 0;
    },
    updateCommentsReply: (state, action) => {
      const commentId = action.payload.commentId;
      const newReplies = action.payload.replies;

      const comments = state.comments.map((comment) => {
        if (comment.id === commentId) {
          return {
            ...comment,
            replies: comment.replies
              ? [...comment.replies, newReplies]
              : [newReplies],
            reply_count: comment.replies ? comment.replies.length + 1 : 1,
          };
        }
        return comment;
      });

      state.comments = comments;
    },
    updateCommentsByProject: (state, action) => {
      const projectDetail = action.payload;

      const modifiedComments = state.comments.map((comment) => {
        if (comment.project.id === projectDetail.id) {
          return {
            ...comment,
            project: {
              ...comment.project,
              emoji: projectDetail.emoji,
              title: projectDetail.title,
            },
          };
        }
        return comment;
      });

      state.comments = modifiedComments;
    },
    // This is for the optimistic func when a user post a message
    cleanComments: (state, action) => {
      const payload = action.payload;

      // Remove the comment without and id and replace it with the newly added comment
      const comments = state.comments.filter(
        (comment) => comment.id !== undefined
      );

      state.comments = [payload, ...comments];
    },
  },
});

export const {
  setComments,
  setActiveCommentId,
  resetCommentState,
  addNewComment,
  addNewReplyToComment,
  deleteComment,
  updateComment,
  addRepliesToComment,
  updateCommentsEmoji,
  updateCommentsReply,
  cleanComments,
  updateCommentsByProject,
} = commentSlice.actions;

export default commentSlice.reducer;
