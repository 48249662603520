import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Workspace } from "../../../../assets/images/icons/logo.svg";
import "./index.scss";
import { useState, useRef } from "react";

import { GlobalHotKeys } from "react-hotkeys";
import { useEffect } from "react";
import NotifierHelper from "../../../../application/common/NotifierHelper";
import { MentionsInput, Mention } from "react-mentions";
import supabase from "../../../Supabase";
import { SUPABASE_TABLE_NAME } from "../../../Supabase/constants";
import { deleteComment } from "../../../../store/reducers/commentSlice";

export default function UpdatePill({ task, className }) {
  const [isEditable, setIsEditable] = useState(false);
  const userInfo = useSelector((state) => state.user.userInfo);
  const members = useSelector((state) => state.members.workspace);
  const workSpaceInfo = useSelector((state) => state.user.workSpaceInfo);
  const [pillText, setPillText] = useState("");
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEditable) setPillText("");
  }, [isEditable]);

  const keyMap = {
    editPill: "u",
    ...(isEditable && { unSelect: "esc" }),
  };

  const keyHandlers = {
    editPill: () => {
      setIsEditable(!isEditable);
      setTimeout(() => document.getElementById("comment-input").focus());
    },
    unSelect: () => {
      setIsEditable(false);
    },
  };

  const handleSendUpdate = async () => {
    setIsEditable(false);
    if (pillText === "") return;
    const mentionUserIds =
      pillText
        .match(/user:([a-f0-9-]+)/g)
        ?.map((match) => match.split(":")[1]) ?? [];
    const { data, error } = await supabase
      .from(SUPABASE_TABLE_NAME.COMMENTS)
      .insert({
        created_by: userInfo.id,
        body: pillText,
        mentioned_users_ids: mentionUserIds,
        workspace_id: workSpaceInfo.id,
      })
      .select("*, createdAt:created_at")
      .single();

    if (error) {
      NotifierHelper.notify("info", error.message);
      return;
    }

    NotifierHelper.notify("update", "Update shared", true, async () => {
      // Something to do
      const { error } = await supabase
        .from(SUPABASE_TABLE_NAME.COMMENTS)
        .delete()
        .eq("id", data.id);

      if (error) {
        NotifierHelper.notify("info", error.message);
        return;
      }

      dispatch(deleteComment(data.id));
    });
  };

  const handlePillClick = () => {
    setIsEditable(true);
    setTimeout(() => document.getElementById("comment-input").focus());
  };

  return (
    <div
      className={`update-pill-container ${
        isEditable ? "active-pill" : ""
      } ${className}`}
      onClick={() => handlePillClick()}
    >
      <div className="dp-user">
        {userInfo?.avatar ? (
          <img src={userInfo.avatar || Workspace} alt="User Profile" />
        ) : (
          <Workspace />
        )}
      </div>
      {isEditable ? (
        <MentionsInput
          placeholder="Share an update or ask a question...!"
          inputRef={inputRef}
          className="pill-input"
          id="comment-input"
          value={pillText}
          onChange={(e) => setPillText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSendUpdate();
            if (e.key === "Escape") {
              setIsEditable(!isEditable);
              setPillText("");
            }
          }}
        >
          <Mention
            trigger={"@"}
            markup="@[__display__](user:__id__)"
            data={members.map((member) => ({
              display: member.username,
              id: member.user_id,
            }))}
            renderSuggestion={(highlightedDisplay, focused) => (
              <div
                className={`user ${focused ? "focused" : ""}`}
                key={highlightedDisplay.id}
              >
                {highlightedDisplay.display}
              </div>
            )}
          />
        </MentionsInput>
      ) : (
        <span className="pill-input">Share an update...</span>
      )}
      <span
        className="mlp-shortcut-tag"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (isEditable) handleSendUpdate();
          else handlePillClick();
        }}
      >
        {isEditable ? "Post" : <span>U</span>}
      </span>

      <GlobalHotKeys
        keyMap={keyMap}
        handlers={keyHandlers}
        allowChanges={true}
      />
    </div>
  );
}
