import NotifierHelper from "../../../application/common/NotifierHelper";
import './index.scss';
import { ReactComponent as DownloadIcon } from "../../../assets/images/icons/download.svg";
import {ReactComponent as CrossIcon} from "../../../assets/images/icons-mlp/x-dismiss.svg"

export default function FileContainer({ fileName, fileLink, isDownload, className, isRemovable, onRemoveClick, ...props }) {
  const downloadFile = () => {
    NotifierHelper.notify("info", "Downloading file!");
    fetch(fileLink)
      .then((r) => r.blob())
      .then((blob) => {
        const element = document.createElement("a");
        document.body.appendChild(element);
        element.setAttribute("href", window.URL.createObjectURL(blob));
        element.setAttribute("download", fileName);
        element.style.display = "";

        element.click();

        document.body.removeChild(element);
      })
      .catch(() =>
        NotifierHelper.notify("info", "Something went wrong while downloading")
      );
  };

  return (
    <div {...props} className={`file-container ${className}`}>
      <p>{fileName}</p>
      {isDownload && <DownloadIcon onClick={() => downloadFile()} />}
      {isRemovable && <CrossIcon onClick={() => onRemoveClick()}/>}
    </div>
  );
}
