import "./emptyProjectsCard.scss";

const EmptyProjectsCard = () => {
  return (
    <div className="empty-card">
      <h6 className="empty-card-heading">
        Download the Coil Mac app to start sharing feedback
      </h6>
      <a
        href="https://hdputkfgtkqnghkkeegq.supabase.co/storage/v1/object/public/coil_app/Coil.dmg"
        download
        className="empty-card-download"
      >
        Download Coil for Mac
      </a>
      {/* <a href="/" className="empty-card-download-windows">
        Need Coil for Windows?
      </a> */}
    </div>
  );
};

export default EmptyProjectsCard;
