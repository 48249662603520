import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { WebContextProvider } from "./application/contexts/WebContext";
import Spotlight from "./infrastructure/Common/Spotlight";

import "./assets/styles/index.scss";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-tooltip/dist/react-tooltip.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import store from "./store";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://1803a05b357148748acb0993185269a1@o4504898284552192.ingest.sentry.io/4504899098181633",
  tracesSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  integrations: [new BrowserTracing()],
});

ReactDOM.render(
  <React.StrictMode>
    <WebContextProvider>
      <Provider store={store}>
        <BrowserRouter>
          {/* App needs to be in root, don't change it */}
          <Spotlight>
            <App />
          </Spotlight>
        </BrowserRouter>
      </Provider>
    </WebContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
