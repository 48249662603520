import { useSelector } from "react-redux";
import googleLogo from "../../assets/svgs/Google.svg";
import outlookLogo from "../../assets/svgs/Outlook.svg";
import arrowTopRight from "../../assets/svgs/arrow-top-right.svg";

const ConfirmEmail = () => {
  const user = JSON.parse(localStorage.getItem("coilUserEmail")) ?? "";

  return (
    <div className="auth-wrapper">
      <div className="confirm-email-container">
        <div className="top-logo mb-3 pb-3"></div>
        <div className="meta-container mb-5 pb-1">
          <h1 className="text-center auth-heading">Check Your Email</h1>
          <p className="m-0 p-0 auth-subheading">
            A link has been sent to {user.email}
          </p>
        </div>

        <div className="email-button-container">
          <a
            href="https://mail.google.com/mail/u/0/#inbox"
            target="_blank"
            rel="noopener noreferrer"
            className="email-button"
          >
            <div className="image-text-container">
              <img src={googleLogo} alt="Google" />
              <span>Open Gmail</span>
            </div>
            <div className="arrow-button">
              <img src={arrowTopRight} alt="arrow top right" />
            </div>
          </a>
          <a
            href="https://outlook.live.com/owa/?nlp=1"
            target="_blank"
            rel="noopener noreferrer"
            className="email-button"
          >
            <div className="image-text-container">
              <img src={outlookLogo} alt="Outlook" />
              <span>Open Outlook</span>
            </div>
            <div className="arrow-button">
              <img src={arrowTopRight} alt="arrow top right" />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
