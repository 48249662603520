import React, { useState } from "react";

const WebContext = React.createContext({
  appTheme: "",
  switchTheme: () => {},

  currentResults: [],
  setCurrentResults: () => {},

  allResults: [],
  setAllResults: () => {},

  inputValue: "",
  setInputValue: () => {},

  reloadSpotLight: () => {},

  actions: [],
  setActions: () => {},
});

export const WebContextProvider = (props) => {
  const initialTheme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light-theme";
  const [theme, setTheme] = useState(initialTheme);

  // search results for Quick Actions
  const [currentResults, setCurrentResults] = useState([]);
  const [allResults, setAllResults] = useState([]);

  const [shouldTriggerReload, setShouldTriggerReload] = useState(false);

  const [actions, setActions] = useState([]);

  const themeHandler = (th) => {
    console.log("THEME CHANGE-Context", th);
    setTheme(th);
    localStorage.setItem("theme", th);
  };

  const reloadSpotLight = (data) => {
    // to delete the project from Quick Actions
    if (data) {
      const filterActions = actions?.filter((e) => e?.id !== data?.id);
      setActions(filterActions);
    }
    setShouldTriggerReload(false);
    setTimeout(() => {
      setShouldTriggerReload(true);
    }, 200);
  };

  const contextValue = {
    appTheme: theme,
    switchTheme: themeHandler,
    currentResults,
    setCurrentResults,
    allResults,
    setAllResults,
    reloadSpotLight,
    shouldTriggerReload,
    setShouldTriggerReload,
    actions,
    setActions,
  };

  return (
    <WebContext.Provider value={contextValue}>
      {props.children}
    </WebContext.Provider>
  );
};

export default WebContext;
