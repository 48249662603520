import { useState } from "react";
import { orderBy } from "lodash";
import ReplyCard from "./ReplyCard";

import { ReactComponent as Right } from "../../../assets/images/icons/arrow-right.svg";
import "./index.scss";
import supabase from "../../Supabase";
import { SUPABASE_RPC } from "../../Supabase/constants";
import { useDispatch } from "react-redux";
import { addRepliesToComment } from "../../../store/reducers/commentSlice";

export default function Replies({
  replies,
  replyCount,
  commentId,
  workSpaceId,
  addReplies,
  className,
}) {
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);
  const sortedReplies = orderBy(replies, "createdAt", "desc");

  const handleShowAll = async () => {
    if (replies.length === replyCount) {
      setShowAll(!showAll);
    } else {
      setShowAll(true);
      const { data: newReplies } = await supabase.rpc(
        SUPABASE_RPC.GET_REPLIES,
        {
          commentid: commentId,
          workspaceid: workSpaceId,
        }
      );

      dispatch(
        addRepliesToComment({
          commentId,
          replies: newReplies,
        })
      );
    }
  };

  return (
    <div className={`reply-wrapper ${className}`}>
      <hr className="border" />
      {showAll
        ? sortedReplies.map((rep) => {
            return (
              <ReplyCard
                className="reply-card-style"
                reply={rep}
                key={rep.id}
              />
            );
          })
        : sortedReplies.splice(0, 1).map((rep) => {
            return (
              <ReplyCard
                className="reply-card-style"
                reply={rep}
                key={rep.id}
              />
            );
          })}
      {/* To be reviewed */}
      {/* {replyLoading && (
        <img src={asset_loader} className="reply-loader" alt="loading" />
      )} */}
      {replyCount > 1 && (
        <div
          role="button"
          className="view-all-replies-text reply-text"
          onClick={handleShowAll}
        >
          {showAll ? "Hide replies" : "View all " + replyCount + " Replies"}
          {!showAll ? <Right className={`arrow-right`} /> : null}
        </div>
      )}
    </div>
  );
}
