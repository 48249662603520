import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import asset_right_arrow from "./../../assets/images/icons/arrow-right-tail.svg";
import { motion } from "framer-motion";
import { useEffect } from "react";
import supabase from "../../infrastructure/Supabase";
import { SUPABASE_TABLE_NAME } from "../../infrastructure/Supabase/constants";
import AnalyticsHelper from "../../application/common/AnalyticsHelper";
import { setUserData, setWorkSpaceData } from "../../store/reducers/userSlice";

const Welcome = () => {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.user.userInfo);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem("coilUserEmail")) ?? "";

    if (localUser.numOfVisits > 0) {
      navigate("/library");
    }
  }, []);

  useEffect(() => {
    const getUserData = async () => {
      const {
        data: { user },
        error,
      } = await supabase.auth.getUser();

      if (error) {
        return;
      }

      dispatch(setUserData(user));
      await supabase
        .from(SUPABASE_TABLE_NAME.PROFILES)
        .update({ is_verified: true })
        .eq("user_id", user.id);
    };

    if (location.hash.includes("error_code")) {
      navigate("/login");
      return;
    }

    if (Object.keys(userInfo).length === 0) {
      getUserData();
    }
  }, []);

  const makeGroupInSegment = (data) => {
    // Track event in group event in segment
    AnalyticsHelper.segmentGroupEvent(data.id, {
      name: data.title,
      employees: 0,
      plan: "free",
      avatar: data?.downloadURL,
      createdAt: data.created_at,
      email: userInfo?.email,
    });
  };

  const makeWorkSpace = async (data) => {
    return await supabase
      .from(SUPABASE_TABLE_NAME.WORKSPACE)
      .insert({
        title: data.title,
        logo: data?.downloadURL,
        created_by: userInfo.id,
      })
      .select()
      .single();
  };

  const updateUserWithWorkSpace = async (workSpace) => {
    // Add workspace id to role table
    await supabase
      .from(SUPABASE_TABLE_NAME.PROFILES)
      .update({ workspace_id: workSpace.id, role: "owner" })
      .eq("user_id", userInfo.id);
  };

  const makeTaskForWorkSpace = async (workSpace) => {
    const { data: groups, error } = await supabase
      .from(SUPABASE_TABLE_NAME.WORKFLOW_GROUPS)
      .select("*");

    if (error) return;

    const req = [];
    groups.forEach(async (group) => {
      req.push(
        supabase.from(SUPABASE_TABLE_NAME.WORKFLOW_GROUP_STATUS).insert({
          name: group.name,
          workflow_group_id: group.id,
          workspace_id: workSpace.id,
          position: 0,
        })
      );
    });

    await Promise.all(req);
  };

  useEffect(() => {
    const makeDefaultProject = async () => {
      const { data } = await makeWorkSpace({
        title: "My Workspace",
      });

      makeGroupInSegment(data);
      makeTaskForWorkSpace(data);

      updateUserWithWorkSpace(data);

      dispatch(setWorkSpaceData(data));
    };

    makeDefaultProject();
  }, []);

  const handleClick = () => {
    navigate("/how-it-works");
  };

  const boxVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const listVariant = {
    hidden: {
      y: -10,
      opacity: 0,
    },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div>
      <motion.div
        className="auth-wrapper"
        variants={boxVariant}
        animate="visible"
        initial="hidden"
      >
        <div className="text-positioner">
          <motion.h1 className="auth-heading text-start" variants={listVariant}>
            Welcome to Coil, {userInfo.username}
          </motion.h1>
          <div className="welcome-text">
            <motion.p className="m-0 p-0" variants={listVariant}>
              The fastest way to share feedback on <em>anything.</em>
            </motion.p>
            <motion.p
              className="m-0 p-0 auth-subheading text-start"
              variants={listVariant}
            >
              Available on MacOS
            </motion.p>
          </div>
          <motion.button
            className="submit-btn"
            onClick={handleClick}
            variants={listVariant}
          >
            Get Started
            <img className={"icon ms-2"} src={asset_right_arrow} alt="" />
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};

export default Welcome;
