import asset_right_arrow from "./../../assets/images/icons/arrow-right-tail.svg";
import { useState, useRef } from "react";
import Card from "./components/Card";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

const cardData = [
  {
    id: 1,
    image:
      "https://res.cloudinary.com/dwqn6yjrc/image/upload/v1718811784/uqrjpk8kr56mtlinacz3.png",
    title: "Start sharing a feedback",
    subtitle:
      "Click 'Share Feedback' in the menu bar, or use the shortcut CMD+SHIFT+F",
  },
  {
    id: 2,
    image:
      "https://res.cloudinary.com/dwqn6yjrc/image/upload/v1718811785/hkmmredcuuajqvovsuzf.png",
    title: "Click anywhere",
    subtitle:
      "Presentations, websites, PDFs, you name it! just click anywhere.",
  },
  {
    id: 3,
    image:
      "https://res.cloudinary.com/dwqn6yjrc/image/upload/v1718811784/jyjq7tjg0dcxvbt2nngj.png",
    title: "Write your feedback",
    subtitle: "Share your comments and ideas, within context.",
  },
  {
    id: 4,
    image:
      "https://res.cloudinary.com/dwqn6yjrc/image/upload/v1718811784/phglsh7w2anqt8glh2yg.png",
    title: "Share the link",
    subtitle: "All your comments, organized and clear.",
  },
];

const HowItWorks = () => {
  const [cardNumber, setCardNumber] = useState(1);
  const firstNewItemIndex = useRef();

  const navigate = useNavigate();

  const handleClick = () => {
    if (cardNumber < 4) {
      setCardNumber((prevState) => {
        firstNewItemIndex.current = prevState + 1;
        return prevState + 1;
      });
    } else {
      navigate("/library");
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="text-positioner">
        <h1 className="auth-heading">How it works</h1>
        <div className="welcome-text">
          <p className="m-0 p-0 mt-4">Write, click, share. Anywhere...</p>
        </div>
        <motion.button
          whileTap={{
            scale: 0.91,
            transition: {
              duration: 0.2,
            },
          }}
          className="submit-btn"
          onClick={handleClick}
        >
          {cardNumber === cardData.length ? "Go to library" : "Next"}
          <img className={"icon ms-2"} src={asset_right_arrow} alt="" />
        </motion.button>
      </div>

      <div
        className="cards-container"
        style={{
          paddingTop: "20rem",
        }}
      >
        <AnimatePresence exitBeforeEnter>
          {cardData
            .filter((item) => item.id === cardNumber)
            .map((item) => (
              <motion.div
                key={item.id}
                initial={{ y: 50, opacity: 0, scale: 0.8 }}
                animate={{ y: 0, opacity: 1, scale: 1 }}
                exit={{ y: -50, opacity: 0, scale: 0.8 }}
                transition={{
                  duration: 0.4,
                  ease: [0.6, -0.05, 0.01, 0.99],
                }}
              >
                <Card item={item} />
              </motion.div>
            ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default HowItWorks;
