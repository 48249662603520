import { motion } from "framer-motion";

export default function WorkspaceLoadingScreen() {
  return (
    <div className="vh-100 vw-100 d-flex flex-column justify-content-start align-items-center">
      <div className="auth-wrapper vw-100" style={{ width: "fit-content" }}>
        <div className="auth-content">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2.5 }}
            className="meta-container pb-1"
          >
            <h1 className="title fw-thick fs-h3 text-start">
              Good to see you 👋
            </h1>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
            className="text-center dim-mlp"
          >
            Loading Your Workspace...
          </motion.div>
        </div>
      </div>
    </div>
  );
}
