import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep, isEqual } from "lodash";

export const projectSlice = createSlice({
  name: "project",
  initialState: {
    projects: null,
    activeProject: {},
    inputValue: "",
    tasks: null,
    isProjectLoading: true,
    filterBy: null,
    defaultProject: null,
    isEditingProject: false,
  },
  reducers: {
    setDefaultProject: (state, action) => {
      const payload = action.payload;
      state.defaultProject = payload;
    },
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setActiveProject: (state, action) => {
      state.activeProject = action.payload;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    toggleProjectLoading: (state, action) => {
      state.isProjectLoading = action.payload;
    },
    setInputValue: (state, action) => {
      state.inputValue = action.payload;
    },
    setFilterBy: (state, action) => {
      state.filterBy = action.payload;
    },
    resetProjectState: (state) => {
      state.projects = null;
      state.activeProject = {};
      state.inputValue = "";
      state.tasks = null;
      state.isProjectLoading = true;
      state.defaultProject = null;
      state.isEditingProject = false;
    },
    // Add task
    handleAddTask: (state, action) => {
      const newTask = action.payload;

      const updatedProjects = cloneDeep(state.tasks);
      if (updatedProjects === null) {
        state.tasks = [newTask];
        return;
      }
      if (
        updatedProjects.findIndex((project) => project.id === newTask.id) === -1
      )
        updatedProjects.push(newTask);

      // If new task is added then order it in due date
      const sortedArray = updatedProjects.sort((task1, task2) => {
        if (task1.dueDate == null) return 1;
        if (task2.dueDate == null) return -1;
        return new Date(task1.dueDate).getTime() >
          new Date(task2.dueDate).getTime()
          ? 1
          : -1;
      });

      state.tasks = sortedArray;
    },

    // Update task
    handleUpdateTask: (state, action) => {
      const updatedTaskDetails = action.payload;

      const updatedUnAssignTasks = cloneDeep(state.tasks);
      const taskIndex = updatedUnAssignTasks.findIndex(
        (task) => task.id === updatedTaskDetails.id
      );
      if (!isEqual(updatedUnAssignTasks[taskIndex], updatedTaskDetails))
        updatedUnAssignTasks[taskIndex] = updatedTaskDetails;

      // If task is updated then re-order it with due date
      const sortedArray = updatedUnAssignTasks.sort((task1, task2) => {
        if (task1.dueDate == null) return 1;
        if (task2.dueDate == null) return -1;
        return new Date(task1.dueDate).getTime() >
          new Date(task2.dueDate).getTime()
          ? 1
          : -1;
      });

      state.tasks = sortedArray;
    },

    // Delete task from state
    handleDeleteTask: (state, action) => {
      const taskDetail = action.payload;
      const updatedUnAssignTask = cloneDeep(state.tasks);

      const filteredTasks = updatedUnAssignTask?.filter(
        (task) => task.id !== taskDetail.id
      );

      state.tasks = filteredTasks;
    },

    // Add Project
    handleAddProject: (state, action) => {
      const projectDetail = action.payload;
      const clonedProjects = cloneDeep(state.projects);
      if (
        clonedProjects.findIndex(
          (project) => project.id === projectDetail.id
        ) === -1
      )
        clonedProjects.push(projectDetail);
      state.projects = clonedProjects;
    },

    // Update project
    handleUpdateProject: (state, action) => {
      const projectDetail = action.payload;

      const clonedProjects = cloneDeep(state.projects);
      const projectIndex = clonedProjects.findIndex(
        (project) => project.id === projectDetail.id
      );

      // If project id available in task then update status of task
      if (projectIndex > -1) {
        clonedProjects[projectIndex] = projectDetail;
      } else {
        clonedProjects.push(projectDetail);
      }

      state.projects = clonedProjects;
    },

    // Delete Project
    handleDeleteProject: (state, action) => {
      const projectDetail = action.payload;
      const updatedProjects = cloneDeep(state.projects);

      const filteredProjects = updatedProjects.filter(
        (project) => project.id !== projectDetail.id
      );

      state.projects = filteredProjects;
    },

    // Move task in unassign task
    handleMoveTaskToUnassign: (state, action) => {
      const taskDetail = action.payload;

      if (!state.tasks.some((task) => task.id === taskDetail.id)) {
        const updatedProjects = cloneDeep(state.projects);
        const clonedUnassignProject = cloneDeep(state.tasks);

        const projectIndex = updatedProjects.findIndex(
          (project) => project.id === taskDetail.project
        );

        const updatedWorklist = updatedProjects[projectIndex].workList.filter(
          (task) => task.id !== taskDetail.id
        );

        updatedProjects[projectIndex].workList = updatedWorklist;

        state.projects = updatedProjects;
        clonedUnassignProject.push(taskDetail);
        state.tasks = clonedUnassignProject;
      }
    },

    // Swap task from one project to another
    handleMoveTaskToProject: (state, action) => {
      const taskDetail = action.payload.task;
      const newProjectId = action.payload.projectId;

      const updatedProjects = cloneDeep(state.projects);
      const clonedUnassignTasks = cloneDeep(state.tasks);

      // Check if the task is present in unassign tasks then remove and update it.
      if (
        clonedUnassignTasks.findIndex((task) => task.id === taskDetail.id) > -1
      ) {
        const filteredTasks = clonedUnassignTasks.filter(
          (task) => task.id !== taskDetail.id
        );

        state.tasks = filteredTasks;
      } else {
        const projectIndex = updatedProjects.findIndex(
          (project) => project.id === taskDetail.project
        );

        // First remove the task from existing project
        const updatedWorklist = updatedProjects[projectIndex].workList.filter(
          (task) => task.id !== taskDetail.id
        );
        // Then update the work list of the same previous project
        updatedProjects[projectIndex].workList = updatedWorklist;
      }

      // Now adding the task into new project worklist
      updatedProjects[
        updatedProjects.findIndex((project) => project.id === newProjectId)
      ].workList.unshift({ ...taskDetail, project: newProjectId });

      // Update the state with new projects
      state.projects = updatedProjects;
    },

    // Remove empty projects
    removeProjectWithOutDetails: (state) => {
      const clonedProjects = cloneDeep(state.projects);
      const filteredProjects = clonedProjects.filter((project) => project.id);

      state.projects = filteredProjects;
    },

    // Update state for editing values
    updateEditState: (state, action) => {
      state.isEditingProject = action.payload;
    },
  },
});

export const {
  setDefaultProject,
  setProjects,
  setActiveProject,
  setInputValue,
  handleDeleteTask,
  handleDeleteProject,
  handleUpdateProject,
  handleUpdateTask,
  handleAddProject,
  handleAddTask,
  resetProjectState,
  removeProjectWithOutDetails,
  setTasks,
  toggleProjectLoading,
  setFilterBy,
  updateEditState,
} = projectSlice.actions;

export default projectSlice.reducer;
