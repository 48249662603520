import { LuPlus } from "react-icons/lu";
import EmojiPicker, { Theme } from "emoji-picker-react";
import "./commentCard.scss";
import { useEffect, useState } from "react";
import supabase from "../../infrastructure/Supabase";
import CommentButton from "./CommentButton";

const CommentCard = ({ comment, index, reactions }) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [emojiReactions, setEmojiReactions] = useState([]);

  useEffect(() => {
    const countOccurrences = reactions.reduce((acc, item) => {
      const key = item.emoji;

      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {});

    const resultEmojis = Object.keys(countOccurrences).map((key) => ({
      emoji: key,
      count: countOccurrences[key],
    }));

    setEmojiReactions(resultEmojis);
  }, [reactions]);

  const handleEmojiSubmit = async (emoji) => {
    const emojiData = [
      {
        comment_id: comment.id,
        emoji,
      },
    ];

    await supabase.from("reactions").insert(emojiData);

    setShowEmojiPicker(false);
  };

  return (
    <div className="comment-card">
      <p className="comment-title">
        <span className="comment-number">{index + 1}</span>
        <span>{comment.body}</span>
      </p>
      <img src={comment.file_link} alt={comment.body} className="comment-img" />

      {/* <div className="comment-footer">
        <div className="emoji-container">
          <>
            {emojiReactions.length === 0 && (
              <>
                <button
                  key={Math.random()}
                  className="emoji-group"
                  onClick={() => handleEmojiSubmit("2705")}
                >
                  <img
                    src={`https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/${"2705"}.png`}
                    alt="emoji"
                    className="emoji-image"
                  />
                </button>
                <button
                  key={Math.random()}
                  className="emoji-group"
                  onClick={() => handleEmojiSubmit("1f44d")}
                >
                  <img
                    src={`https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/${"1f44d"}.png`}
                    alt="emoji"
                    className="emoji-image"
                  />
                </button>
                <button
                  key={Math.random()}
                  className="emoji-group"
                  onClick={() => handleEmojiSubmit("1f525")}
                >
                  <img
                    src={`https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/${"1f525"}.png`}
                    alt="emoji"
                    className="emoji-image"
                  />
                </button>
              </>
            )}
          </>

          {emojiReactions.map((item) => (
            <CommentButton
              key={Math.random()}
              item={item}
              handleEmojiSubmit={handleEmojiSubmit}
            />
          ))}
        </div>
        <button
          className="comment-reaction-button"
          onClick={() => setShowEmojiPicker(true)}
        >
          <LuPlus size={24} />
        </button>
      </div>
      <div className="emoji-picker">
        {showEmojiPicker ? (
          <>
            <div
              className="picker-overlay"
              onClick={() => setShowEmojiPicker(false)}
            ></div>
            <EmojiPicker
              onEmojiClick={(e) => handleEmojiSubmit(e.unified)}
              autoFocusSearch={false}
              theme={Theme.LIGHT}
              skinTonesDisabled
            />
          </>
        ) : null}
      </div> */}
    </div>
  );
};

export default CommentCard;
