import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { LuLink, LuLock, LuTrash2, LuUnlock } from "react-icons/lu";
import { format } from "date-fns";
import "./library.scss";
import { truncateString } from "../../utils/truncateString";
import supabase from "../../infrastructure/Supabase";
import NotifierHelper from "../../application/common/NotifierHelper";
import EmptyProjectsCard from "../../components/EmptyProjectsCard/EmptyProjectsCard";

const Library = () => {
  const [isPrivate, setIsPrivate] = useState(false);
  const userInfo = useSelector((state) => state.user.userInfo);
  const projects = useSelector((state) => state.project.projects);
  const comments = useSelector((state) => state.comment.comments);

  useEffect(() => {
    // Add project number to supabase customer table in links
    const updateCustomerData = async () => {
      const { data: customerData } = await supabase
        .from("customers")
        .select()
        .eq("user_id", userInfo.id);

      if (customerData.length > 0) {
        const { error: customerError } = await supabase
          .from("customers")
          .update({ coil_link_number: projects !== null ? projects.length : 0 })
          .eq("user_id", userInfo.id);
      } else {
        const { data: supabaseData, error: supabaseError } = await supabase
          .from("customers")
          .insert([
            {
              user_id: userInfo.id,
              coil_link_number: projects !== null ? projects.length : 0,
            },
          ]);
      }

      // TODO: handle customer fetching error
    };

    updateCustomerData();
  }, []);

  const DEFAULT_PROJECT_URL =
    "https://images.unsplash.com/photo-1613909207039-6b173b755cc1?q=80&w=2147&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

  const getCommentsNumber = (projectId) => {
    const projectCommentNumbers = comments.filter(
      (comment) => comment.project.id === projectId
    );

    const commentNumber = projectCommentNumbers.length ?? 0;

    return `${commentNumber} ${
      commentNumber === 0 || commentNumber === 1 ? "comment" : "comments"
    }`;
  };

  const getProjectComments = (projectId) => {
    const projectComments = comments.filter(
      (comment) => comment.project.id === projectId
    );

    return projectComments;
  };

  const handlePrivateLinkChange = () => {
    // TODO: This should set the comment to private or public
    setIsPrivate((prevState) => !prevState);
  };

  const handleDeleteProject = async (projectId) => {
    const { data, error } = await supabase
      .from("project")
      .delete()
      .eq("id", projectId);

    if (error) {
      NotifierHelper.notify("info", error.message);
      console.error("Error deleting Project:", error);
    } else {
      console.log("Project deleted:", data);
      NotifierHelper.notify("info", "Project successfully deleted!");
    }
  };

  const handleCopyLink = (projectId) => {
    navigator.clipboard
      .writeText(`feedback.coil.so/s/${projectId}`)
      .then(() => {
        NotifierHelper.notify("info", "Link Copied!");
      })
      .catch(() => {
        NotifierHelper.notify(
          "info",
          "Can not copy the link! Please try again."
        );
      });
  };

  console.log(projects);

  return (
    <div className="w-100">
      <div className="projects-folder-container">
        <div className="project-folder-grid">
          {(projects?.length === 0 || projects === null) && (
            <EmptyProjectsCard />
          )}
          {projects !== null &&
            projects.map((project) => (
              <div key={project.id} className="project-folder-card">
                <div className="project-image-group">
                  {getProjectComments(project.id)?.length >= 3 ? (
                    <div className="project-image-container">
                      <div className="bg-image-overlay-group">
                        <img
                          src={
                            getProjectComments(project.id)[0]?.file_link ??
                            DEFAULT_PROJECT_URL
                          }
                          alt="Project"
                          className="project-folder-image rotate-right"
                        />
                        <div className="image-overlay-right" />
                      </div>
                      <div className="bg-image-overlay-group">
                        <img
                          src={
                            getProjectComments(project.id)[1]?.file_link ??
                            DEFAULT_PROJECT_URL
                          }
                          alt="Project"
                          className="project-folder-image rotate-left"
                        />
                        <div className="image-overlay-left" />
                      </div>
                      <div className="bg-image-overlay-group">
                        <img
                          src={
                            getProjectComments(project.id)[1]?.file_link ??
                            DEFAULT_PROJECT_URL
                          }
                          alt="Project"
                          className="project-folder-image"
                        />
                        <div className="image-overlay" />
                      </div>
                    </div>
                  ) : getProjectComments(project.id)?.length === 2 ? (
                    <div className="project-image-container">
                      <div className="bg-image-overlay-group">
                        <img
                          src={
                            getProjectComments(project.id)[0]?.file_link ??
                            DEFAULT_PROJECT_URL
                          }
                          alt="Project"
                          className="project-folder-image rotate-right"
                        />
                        <div className="image-overlay-right" />
                      </div>
                      <div className="bg-image-overlay-group">
                        <img
                          src={
                            getProjectComments(project.id)[1]?.file_link ??
                            DEFAULT_PROJECT_URL
                          }
                          alt="Project"
                          className="project-folder-image"
                        />
                        <div className="image-overlay" />
                      </div>
                    </div>
                  ) : (
                    <div className="project-image-container">
                      <img
                        src={
                          getProjectComments(project.id)[0]?.file_link ??
                          DEFAULT_PROJECT_URL
                        }
                        alt="Project"
                        className="project-folder-image"
                      />
                      <div className="image-overlay" />
                    </div>
                  )}
                  <p className="comment-badge">
                    {getCommentsNumber(project.id)}
                  </p>
                </div>

                <Link
                  to={`/s/${project.id}`}
                  state={getProjectComments(project.id)}
                  className="project-folder-header"
                >
                  <h6 className="project-folder-header">
                    {truncateString(project.title, 30)}
                  </h6>
                </Link>

                <div className="project-folder-footer">
                  <p>
                    {format(
                      new Date(project.created_at.slice(0, 10)),
                      "LLL dd, yyyy"
                    )}
                  </p>
                  <div className="footer-button-container">
                    {/* <button
                    className={`footer-button ${
                      isPrivate && "private-link-bg"
                    }`}
                    onClick={handlePrivateLinkChange}
                  >
                    {isPrivate ? <LuLock /> : <LuUnlock />}
                  </button> */}
                    <button
                      className="footer-button"
                      onClick={() => handleCopyLink(project.id)}
                    >
                      <LuLink />
                    </button>
                    <button
                      className="footer-button"
                      onClick={() => handleDeleteProject(project.id)}
                    >
                      <LuTrash2 />
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Library;
