import "./index.scss";
import { Mention, MentionsInput } from "react-mentions";
import { getTimeAgo } from "../../../../application/common/helper";
import FileContainer from "../../FileContainer";
import { MenuIcon } from "../../../../assets/AjxIcons";
import { imageDetector } from "../../../../application/utils/imageDetector";

export default function ReplyCard({ reply, className }) {
  const defaultAvatar = "https://i.ibb.co/JFR6LyW/user.png";

  return (
    <div className={`reply-card ${className}`} key={reply.id}>
      <div className="user-and-time d-flex align-items-center gap-2">
        <div className="dp-user">
          <img src={reply.user?.avatar || defaultAvatar} alt="User Profile" />
        </div>
        <div className="icon-wraper">
          <div className="reply-details-wraper">
            <span className="user-name">{reply.user.name}</span>
            <span className="time">{getTimeAgo(reply.createdAt)}</span>
          </div>
          <MenuIcon className={"icon"} />
        </div>
      </div>
      <div className="notification">
        <MentionsInput
          className={`pill-input ${!reply.id ? "not-active" : ""}`}
          disabled
          value={reply.body}
        >
          <Mention
            displayTransform={(_, display) => `@${display}`}
            markup="@[__display__](user:__id__)"
            className="mention"
          />
        </MentionsInput>
      </div>

      {reply.file_link && imageDetector(reply.file_link) ? (
        <div className="image-container">
          <img className="post-image" src={reply.file_link} alt="Loading..." />
        </div>
      ) : reply.file_link ? (
        <FileContainer
          fileLink={reply.file_link}
          className="mt-0 border-0"
          fileName={reply.file_link?.split("comment-files/")[1].split("reply.")}
          isDownload
        />
      ) : null}
    </div>
  );
}
