import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useContext,
} from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";

import { GlobalHotKeys } from "react-hotkeys";

import { toInteger, cloneDeep, omit } from "lodash";
import EmojiPicker, { EmojiStyle, Theme, Emoji } from "emoji-picker-react";

import DeleteConfirmation from "../../../infrastructure/Common/DeleteConfirmation";
import TaskCard from "../../../infrastructure/Common/TaskCard";

import NotifierHelper from "../../../application/common/NotifierHelper";

import asset_arrow_back from "./../../../assets/images/icons/arrow-left-tail.svg";
// import asset_dots from "./../../../assets/images/icons/three-dots.svg";
import { ReactComponent as AssetDot } from "./../../../assets/images/icons/three-dots.svg";

import { ReactComponent as Folder } from "./../../../assets/images/icons-mlp/folder.svg";
import { ReactComponent as Trash } from "./../../../assets/images/icons-mlp/trash.svg";

import "./project-session.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  handleAddProject,
  handleDeleteTask,
  handleUpdateProject,
  handleUpdateTask,
  setInputValue,
  handleDeleteProject as deleteProject,
} from "../../../store/reducers/projectSlice";
import { handleDDAddNewProject } from "../../../store/reducers/dropDownSlice";
import WebContext from "../../../application/contexts/WebContext";
import { nanoid } from "nanoid";
import { useKBar } from "kbar";
import AnalyticsHelper from "../../../application/common/AnalyticsHelper";
import Loader from "../../../infrastructure/Common/MasterLoader";
import supabase from "../../../infrastructure/Supabase";
import { SUPABASE_TABLE_NAME } from "../../../infrastructure/Supabase/constants";
import CreateWorkspaceModal from "../../../infrastructure/Common/Modal/CreateWorkspaceModal";

const ProjectSession = (props) => {
  let { projectId } = useParams();
  const navigate = useNavigate();
  const webCtx = useContext(WebContext);

  const userInfo = useSelector((state) => state.user.userInfo);
  const workSpaceInfo = useSelector((state) => state.user.workSpaceInfo);
  const projects = useSelector((state) => state.project.projects);
  const tasks = useSelector((state) => state.project.tasks);
  const inputValue = useSelector((state) => state.project.inputValue);
  const dispatch = useDispatch();
  const [workspaceModalOpen, setWorkspaceModalOpen] = useState(false);
  // const workflowStatutes = useSelector(
  //   (state) => state.dropdown.workflowStatus
  // );
  const { query } = useKBar();
  const taskLists = tasks?.filter((e) => e?.project_id === projectId);

  const keyMap = {
    enterNotes: "/",
    scAddMilestone: "shift+l",
    // addSession: "t",
    arrowUp: "up",
    arrowDown: "down",
    goBack: ["esc", "alt+left", "command+left", "ctrl+left"],
  };

  // const taskOrder = workflowStatutes.map((workflow) =>
  //   workflow.name.toLowerCase()
  // );

  const project = projects?.find((proj) => proj.id === projectId) ?? {};

  const [modal, setModal] = useState("");
  const [modalData, setModalData] = useState([]);
  const [focus, setFocus] = useState(-1);
  const [clearDelay, setClearDelay] = useState();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const projectRef = useRef(project);
  projectRef.current = project;

  // const groupTaskWithStatus = (tasklist) => {
  //   // First group task by its status
  //   const grouped = groupBy(tasklist, "status");

  //   // Now using one another array to have exact a same task order as per our UI.
  //   const orderedArray = [];
  //   console.log("taskOrder", taskOrder);
  //   // Looping through that grouped object and pushed that all task in that orderArray.
  //   taskOrder.forEach((key) => {
  //     if (grouped[key]) orderedArray.push(...grouped[key]);
  //   });

  //   // Now we making the final object according to its status
  //   // Cause we're using this index to move through task with keyboard and to maintain the exact same order as per UI
  //   // After that making final object according to status
  //   return groupBy(
  //     orderedArray.map((item, i) => ({ ...item, index: i })),
  //     "status"
  //   );
  // };

  // const workList = useMemo(() => {
  //   const taskLists = tasks?.filter((e) => e?.project_id === projectId);
  //   return groupTaskWithStatus(taskLists);
  // }, [tasks, workflowStatutes]);

  useLayoutEffect(
    () => {
      if (Object.keys(project).length === 0 && projectId === "new")
        newProject();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (Object.keys(project).length !== 0) {
      syncProjects();
      clearTimeout(clearDelay);
      setClearDelay(
        setTimeout(() => {
          onSaveProject({ ...project });
        }, 1000)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const newProject = async () => {
    if (Object.keys(workSpaceInfo).length === 0) {
      setTimeout(() => navigate("/no-workspace"));
      return;
    }

    let dummyProjectName;
    if (inputValue === "") {
      dummyProjectName = `Project-${nanoid(4)}`;
      dispatch(setInputValue(dummyProjectName));
    }

    const data = {
      title: inputValue || dummyProjectName,
      workspace_id: workSpaceInfo.id,
      created_by: userInfo.id,
    };

    const { data: projectData, error } = await supabase
      .from(SUPABASE_TABLE_NAME.PROJECT)
      .insert(data)
      .select()
      .limit(1)
      .single();

    if (error) {
      navigate("/projects");
      NotifierHelper.notify("info", error.message);
      return;
    }

    dispatch(setInputValue(""));

    const temp = { ...projectRef.current };
    Object.assign(projectData, temp);

    AnalyticsHelper.trackEvent("Project Created", {
      projectId: projectData.id,
      title: projectData.title,
    });
    // AnalyticsHelper.smartLookTrackEvent('Project Created', {
    //     projectId: projectData.id,
    //     title: projectData.title
    // })

    dispatch(handleUpdateProject(projectData));
    if (window.location.pathname.includes("tags/new")) {
      // setProject(projectData);
      navigate(`/tags/${projectData.id}`);
    }
  };

  const handleDateChange = (date, type, session) => {
    const isoFormatDate = date ? new Date(date).toISOString() : null;
    switch (type) {
      // case 'start':
      //     setProject({ ...project, startDate: isoFormatDate });
      //     break;
      // case 'due':
      //     setProject({ ...project, dueDate: isoFormatDate });
      //     break;
      case "milestone":
        const updatedSession = { ...session, dueDate: isoFormatDate };
        onSaveSession(updatedSession);
        dispatch(handleUpdateTask(updatedSession));
        break;
      // case 'task':
      //     let proj = cloneDeep(project);
      //     // proj.workList[indxOrId].tasks[tIndex]['dueDate'] = isoFormatDate;
      //     setProject(proj);
      //     break;
      default:
        break;
    }
  };

  const handleChange = (
    e,
    item = null,
    session,
    indxOrId = null,
    stIndex = null
  ) => {
    const { name, value, checked } = e.target;
    // if (item === 'milestone') {
    //     temp.sessions[msIndx][name] = value;
    //     setProject(temp);
    // } else if (item === 'task-checkbox') {
    //     temp.sessions[msIndx].tasks[indxOrId].isCompleted = checked;
    //     temp.sessions.forEach(ms => { ms.current = calcMilestoneProgress(ms) });
    //     setProject(temp);
    // } else if (item === 'task') {
    //     temp.sessions[msIndx].tasks[indxOrId][name] = value;
    //     // temp.sessions.forEach(ms => { ms.current = calcMilestoneProgress(ms) });
    //     setProject(temp);
    // } else if (item === 'sub-task') {
    //     temp.sessions[msIndx].tasks[indxOrId].subTasks[stIndex][name] = value;
    //     setProject(temp);
    // } else if (item === 'subtask-checkbox') {
    //     temp.sessions[msIndx].tasks[indxOrId].subTasks[stIndex].isCompleted = checked;
    //     temp.sessions.forEach(ms => { ms.current = calcMilestoneProgress(ms) });
    //     setProject(temp);
    // } else
    if (item === "main-checkbox") {
      const updatedSession = {
        ...session,
        status: checked ? "completed" : "todo",
      };
      onSaveSession(updatedSession);
      dispatch(handleUpdateTask(updatedSession));
    } else {
      dispatch(handleUpdateProject({ ...project, [name]: value }));
      // setProject({ ...project, [name]: value });
    }
  };

  const handleMilestone = (action, msIndex = null) => {
    let temp = cloneDeep(project);
    if (action === "add") {
      temp.sessions.push({
        title: "",
        dueDate: null,
        identityCode: Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, ""),
        tasks: [],
        project: projectId,
      });
    }
    if (action === "remove") {
      temp.sessions.splice(msIndex, 1);
    }
    dispatch(handleUpdateProject(temp));
  };

  const handleTask = (msIndex, action, tIndex = null, tValue = null) => {
    let temp = cloneDeep(project);
    if (action === "add") {
      temp.sessions[msIndex].tasks.push({
        title: tValue ? tValue : "",
        description: "",
        dueDate: null,
        identityCode: Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, ""),
        isCompleted: false,
        // editable: true,
        focus: true,
        subTasks: [],
      });
    }
    if (action === "remove") {
      temp.sessions[msIndex].tasks.splice(tIndex, 1);
      if (temp.sessions[msIndex].tasks.length) {
        // handleTask(msIndx, 'remove', tIndex)
        // id={'task_'+msIndx+'_'+tIndex}
        const previousTask = "#t_input_" + msIndex + "_" + (tIndex - 1);
        document.querySelector(previousTask).focus();
        // temp.sessions[msIndex].tasks[tIndex - 1].focus = true;
      }
    }

    if (action === "edit") {
      temp.sessions[msIndex].tasks[tIndex].editable = true;
      // const dd = temp.sessions[msIndex].tasks[tIndex].dueDate;
      // setTaskDate(dd ? dd : null);
    }

    temp.sessions.forEach((ms) => {
      ms.current = calcMilestoneProgress(ms);
    });
    dispatch(handleUpdateProject(temp));
  };

  const handleSubTask = (
    action,
    msIndex,
    tIndex,
    subIndex = null,
    stValue = null
  ) => {
    let temp = cloneDeep(project);
    if (action === "add") {
      temp.sessions[msIndex].tasks[tIndex].subTasks.push({
        title: stValue ? stValue : "",
        description: "",
        dueDate: null,
        identityCode: Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, ""),
        isCompleted: false,
        // editable: true,
        focus: true,
      });
    }
    if (action === "remove") {
      temp.sessions[msIndex].tasks[tIndex].subTasks.splice(subIndex, 1);
      if (temp.sessions[msIndex].tasks[tIndex].subTasks.length) {
        const previousSubTask =
          "#t_input_" + msIndex + "_" + tIndex + "_" + (subIndex - 1);
        document.querySelector(previousSubTask).focus();
      }
    }

    // if (action === 'edit') {
    //     temp.sessions[msIndex].tasks[tIndex].editable = true;
    //     const dd = temp.sessions[msIndex].tasks[tIndex].dueDate;
    //     setTaskDate(dd ? dd : null);
    // }

    // temp.sessions.forEach(ms => { ms.current = calcMilestoneProgress(ms) });
    dispatch(handleUpdateProject(temp));
  };

  const handleKeyPress = (
    e,
    item = null,
    msIndx = null,
    tIndex = null,
    stIndex = null,
    taskData = null
  ) => {
    if (item === "milestone" && e.key === "Enter") {
      handleTask(msIndx, "add");
      document.querySelector("#ms-accordion-" + msIndx).classList.add("show");
      document
        .querySelector("#heading-ms-" + msIndx + " .collapse-arrow")
        .classList.remove("collapsed");
    }
    if (item === "milestone" && e.key === "Escape") {
      document.querySelector("#proj_input_title").blur();
      e.preventDefault();
      return false;
    }
    if (item === "task" && e.key === "Enter") {
      handleTask(msIndx, "add");
    }
    if (item === "sub-task" && e.key === "Enter") {
      handleSubTask("add", msIndx, tIndex);
    }
    if (e.key === "Backspace" && !e.target.value) {
      e.preventDefault();
      if (item === "milestone") {
      } else if (item === "task") {
        handleTask(msIndx, "remove", tIndex);
        if (!project.sessions[msIndx].tasks.length) {
          document
            .querySelector("#ms-accordion-" + msIndx)
            .classList.remove("show");
          document
            .querySelector("#heading-ms-" + msIndx + " .collapse-arrow")
            .classList.add("collapsed");
          document.querySelector("#ms_input_" + msIndx).focus();
        }
      } else {
        handleSubTask("remove", msIndx, tIndex, stIndex);
        handleTask(msIndx, "add", 0, e.target.value);
      }
    }
  };

  const calcMilestoneProgress = (milestone) => {
    if (!milestone.tasks.length) return 0;
    const completed = milestone.tasks.filter((item) => item.isCompleted).length;
    return completed;
  };

  const onSaveProject = async (newProject) => {
    if (!newProject.id) {
      return;
    }
    // Add Project to Quick Actions
    webCtx?.reloadSpotLight();

    const { error } = await supabase
      .from(SUPABASE_TABLE_NAME.PROJECT)
      .upsert(newProject)
      .select()
      .limit(1)
      .single();

    if (error) {
      NotifierHelper.notify("info", error.message);
    }
  };

  const syncProjects = () => {
    dispatch(handleUpdateProject(project));
  };

  // const syncTasks = () => {
  //     const loadedTasks = cloneDeep(sessions);
  //     // const projectTasks = loadedTasks.filter(item => item.project === project.id);
  //     project.workList.forEach((task, i) => {
  //         loadedTasks[loadedTasks.findIndex(el => el._id === task._id)] = task
  //     });
  //     dispatch(setSessions(loadedTasks))
  // }

  // function formatOptionLabel({ label }, { inputValue }) {
  //     return (
  //         <Highlighter
  //             searchWords={[inputValue]}
  //             textToHighlight={label}
  //             highlightClassName="hl-text"
  //         />
  //     );
  // }

  const handleSelectChange = (event, type, session) => {
    const updatedSession = { ...session, assignee: event.value };
    dispatch(handleUpdateTask(updatedSession));
    onSaveSession(updatedSession);

    // add/update sessions listing_
    // syncTasks();
    // let tempSessions = cloneDeep(sessions);
    // let matchedTask = temp.workList[temp.workList.findIndex(el => el._id === tId)];
    // matchedTask.projectDetail = { emoji: project.emoji, title: project.title };
    // tempSessions.push(matchedTask);

    // dispatch(setSessions(tempSessions))
  };

  const handleModal = (name, item = {}, index = null) => {
    setModal(name);
    if (name === "client") {
      const preSetData = {
        type: "preset",
        [item.type]: item.inputValue,
      };
      setModalData(preSetData);
    }
    if (name === "payStructure" && project?.payStructure?.type) {
      setModalData(project.payStructure);
    }
    if (name === "delete-project") {
      setModalData(project);
    }
    if (name === "delete-session") {
      setModalData(item);
    }
  };

  const handleDeleteSession = async (confirm, data) => {
    setModal("");
    const { error } = await supabase
      .from(SUPABASE_TABLE_NAME.TASK)
      .delete()
      .eq("id", data.id);

    if (error) {
      NotifierHelper.notify("info", error.message);
      return;
    }

    NotifierHelper.notify("delete", "Task deleted successfully");
    dispatch(handleDeleteTask(data));
  };

  const handleDeleteProject = async (confirm, data) => {
    dispatch(deleteProject(data));
    NotifierHelper.notify("delete", `Project Deleted`);

    AnalyticsHelper.trackEvent("Project Deleted", {
      projectId: data.id,
      title: data.title,
    });
    // AnalyticsHelper.smartLookTrackEvent('Project Deleted', {
    //     projectId: data.id,
    //     title: data.title
    // })

    setModal("");
    navigate("/projects");

    const { error } = await supabase
      .from(SUPABASE_TABLE_NAME.PROJECT)
      .delete()
      .eq("id", data.id);

    if (error) {
      NotifierHelper.notify("info", error.message);
      dispatch(handleAddProject(data));
      dispatch(handleDDAddNewProject(data));
      return;
    }
    // undoChangeInterval = setInterval(() => {
    //     ProjectsApi.deleteProject(data._id)
    //     .then(res => {
    //         clearInterval(undoChangeInterval)
    //     })
    //     .catch(err => {
    //         console.log(err);
    //         NotifierHelper.notify('info', "something went wrong, please try again later");
    //         clearInterval(undoChangeInterval)
    //     })
    // }, 5000)

    // NotifierHelper.notify('delete', 'Project deleted', true, () => {
    //     dispatch(handleAddProject(data))
    //     clearInterval(undoChangeInterval)
    // });
  };

  const projectToggleArchive = (status) => {
    const updatedProjectData = {
      ...project,
      status: status,
      ...(status === "archived" && { archivedAt: new Date().toISOString() }),
    };

    // setProject(updatedProjectData);
    dispatch(handleUpdateProject(updatedProjectData));
  };

  const keyHandlers = {
    enterNotes: (e) => {
      document.querySelector("#notes").focus();
      e.preventDefault();
      return false;
    },
    scAddMilestone: (e) => {
      handleMilestone("add");
      if (project.sessions.length) {
        setTimeout(() => {
          document
            .querySelector("#ms_input_" + (project.sessions.length - 1))
            .focus();
        });
      }
      e.preventDefault();
      return false;
    },
    addSession: (e) => {
      navigate("/tags/" + project.id + "/canvases/new");
      e.preventDefault();
      return false;
    },
    arrowUp: (e) => {
      handleSessionFocus(e, "up");
      e.preventDefault();
      return false;
    },
    arrowDown: (e) => {
      handleSessionFocus(e, "down");
      e.preventDefault();
      return false;
    },
    goBack: (e) => {
      if (focus > -1) document.querySelector("#session_card_" + focus).blur();
      // setFocus(-1);
      navigate("/home");
      e.preventDefault();
      return false;
    },
    // clearSearch: this.clearSearch
  };

  // const taskIntervals = (workItem) => {
  //   const intervals = reduce(
  //     workItem.tasks,
  //     (result, value) => {
  //       return result.concat(value.timeIntervals);
  //     },
  //     []
  //   );
  //   return intervals;
  // };

  const handleSessionFocus = (e, action) => {
    const taskLists = tasks?.filter((task) => task?.project_id === projectId);
    if (action === "down") {
      if (focus < taskLists.length - 1) {
        setFocus(focus + 1);
        document.querySelector("#session_card_" + (focus + 1)).focus();
      } else {
        setFocus(0);
        document.querySelector("#session_card_0").focus();
      }
    }

    if (action === "up") {
      if (focus > 0) {
        setFocus(focus - 1);
        document.querySelector("#session_card_" + (focus - 1)).focus();
      } else {
        setFocus(taskLists.length - 1);
        document
          .querySelector("#session_card_" + (taskLists.length - 1))
          .focus();
      }
    }

    if (action === "hover" && e.target.id.includes("session_card_")) {
      var index = e.target.id.lastIndexOf("_");
      var result = e.target.id.substr(index + 1);
      setFocus(toInteger(result));
      document.querySelector("#session_card_" + result).focus();
    }
  };

  // const getProjectStatus = () => {
  //     if (project?.status) {
  //         return statuses.find((opt) => opt.value === project.status);
  //     } else return defaultProjectStatus;
  // };

  function onEmojiSelection(emojiData, event) {
    dispatch(handleUpdateProject({ ...project, emoji: emojiData.unified }));
    setShowEmojiPicker(false);
  }

  const onSaveSession = async (newSession) => {
    if (!newSession.id) {
      return;
    }
    const session = omit(newSession, "index");
    const { error } = await supabase
      .from(SUPABASE_TABLE_NAME.TASK)
      .update(session)
      .eq("id", session.id);

    if (error) {
      NotifierHelper.notify("info", error.message);
    }
  };

  return (
    <div className="container-fluid mt-4 pt-2 px-0">
      {tasks === null && <Loader />}
      <section className="mikyal-project-sessionize position-relative">
        <div className="d-flex align-items-start h-100">
          <div className="w-100">
            <div className="about-project px-3">
              <div className="heading d-flex align-items-center justify-content-between">
                <div className="w-100 d-flex align-items-center">
                  <NavLink
                    to="/tags"
                    className="mlp-btn rounded icon return-back me-3 active"
                  >
                    <img src={asset_arrow_back} alt="back" />
                  </NavLink>

                  <div className="pointer me-3 position-relative">
                    <div
                      className="project-emoji"
                      onClick={() => setShowEmojiPicker(true)}
                    >
                      <Emoji
                        unified={project.emoji || "1f31f"}
                        emojiStyle={EmojiStyle.APPLE}
                        size={24}
                      />
                    </div>

                    <div className="emoji-picker">
                      {showEmojiPicker ? (
                        <>
                          <div
                            className="picker-overlay"
                            onClick={() => setShowEmojiPicker(false)}
                          ></div>
                          <EmojiPicker
                            onEmojiClick={onEmojiSelection}
                            autoFocusSearch={false}
                            theme={Theme.AUTO}
                            skinTonesDisabled
                            defaultSkinTone={"1f3fc"}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>

                  <input
                    id={"proj_input_title"}
                    type="text"
                    className="mikyal-borderless-field px-0 pt-0 title-lh pt-1"
                    name="title"
                    placeholder="A New Tag..."
                    // value={project.title === 'A New Project...' ? '' : project.title}
                    value={project.title || inputValue}
                    onChange={handleChange}
                    onKeyDown={(e) => handleKeyPress(e, "milestone")}
                    // autoFocus
                  />
                </div>
                <div className="addition d-flex align-items-center">
                  <button
                    className="mlp-btn large no-wrap me-2"
                    onClick={() => {
                      query.setCurrentRootAction("canvas");
                      query.toggle();
                    }}
                  >
                    + New Canvas
                    <span className="mlp-shortcut-tag ml">C</span>
                  </button>
                  <div
                    className="dd-action dropdown ms-1 me-4"
                    placement="left"
                  >
                    <div
                      className="action-dots dropdown-toggle px-1"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <AssetDot />
                    </div>

                    <div
                      className="dd-data dropdown-menu dropstart"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <div
                        className="dd-item"
                        onClick={() =>
                          projectToggleArchive(
                            project.status === "archived"
                              ? "active"
                              : "archived"
                          )
                        }
                      >
                        <span className="me-2">
                          <Folder />
                        </span>
                        {project.status === "archived"
                          ? "Active Project"
                          : "Archive project"}
                      </div>

                      <div
                        className="dd-item"
                        onClick={() => handleModal("delete-project")}
                      >
                        <span className="me-2">
                          <Trash />
                        </span>
                        Delete project
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="about">
                                <div className="project-meta">

                                    <div className="meta-item">
                                        <span className="text-muted">Status: &nbsp;</span>
                                        <div className="mikyal-selector">
                                            <Select
                                                options={statuses ? statuses : []}
                                                className="mikyal-select concise"
                                                classNamePrefix="mikyal-select"
                                                formatOptionLabel={formatOptionLabel}
                                                placeholder="+ Set Status"
                                                value={getProjectStatus()}
                                                onChange={e => handleSelectChange("status", e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="meta-item ms-4">
                                        <span className="text-muted">Due: &nbsp;</span>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                variant='dialog'
                                                emptyLabel="+ Set Due Date"
                                                format="MMM dd, yyyy"
                                                InputProps={{ className: !project.dueDate ? 'text-dim' : '' }}
                                                value={project.dueDate ? project.dueDate : null}
                                                onChange={date => handleDateChange(date, 'due')}
                                                disableToolbar={true}
                                                cancelLabel=""
                                                okLabel="Done"
                                                clearable={true}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>

                                </div>
                            </div> */}
            </div>
            <div className="project-milestones-sessionized">
              {/* <div className="session-spacing d-flex align-items-center">
                                Tasks
                                <div className="shortcut-indicator ms-2 fw-semi slash pointer" onClick={() => navigate('/tags/' + project.id + '/canvases/new')}>T</div>
                            </div> */}
              {taskLists && taskLists.length > 0 ? (
                taskLists.map((item, index) => (
                  <TaskCard
                    key={item._id}
                    session={item}
                    index={index}
                    handleModal={handleModal}
                    handleSessionFocus={handleSessionFocus}
                    projectId={project.id}
                    isTask={true}
                    handleChange={handleChange}
                    handleDateChange={handleDateChange}
                    handleSelectChange={handleSelectChange}
                  />
                ))
              ) : (
                <div className="text-second mt-2 ps-3">
                  Let’s get started! press{" "}
                  <span className="mlp-shortcut-tag">T</span> to create your
                  first task.
                </div>
              )}
              {/* {Object.keys(workList).length ? (
                taskOrder.map((oItem) => (
                  <div key={oItem}>
                    {workList[oItem] && (
                      <StatusTitle
                        status={oItem}
                        data={workList[oItem]}
                        totalTimeTracked={secondsToTimestamp(
                          sumBy(workList[oItem], (session) =>
                            reduce(
                              taskIntervals(session),
                              (total, interval) =>
                                total + parseInt(interval.duration),
                              0
                            )
                          )
                        )}
                      />
                    )}

                    {workList[oItem] &&
                      workList[oItem].length &&
                      workList[oItem].map((item) => (
                        <TaskCard
                          key={item._id}
                          session={item}
                          handleModal={handleModal}
                          handleSessionFocus={handleSessionFocus}
                          projectId={project.id}
                          isTask={true}
                          handleChange={handleChange}
                          handleDateChange={handleDateChange}
                          handleSelectChange={handleSelectChange}
                        />
                      ))}
                  </div>
                ))
              ) : (
                <div className="text-second mt-2 ps-3">
                  Let’s get started! press{" "}
                  <span className="mlp-shortcut-tag">T</span> to create your
                  first task.
                </div>
              )} */}
            </div>
          </div>
        </div>
      </section>

      {modal && (
        <React.Fragment>
          <DeleteConfirmation
            setModal={setModal}
            modal={modal === "delete-session"}
            data={modalData}
            onDeleteSuccess={handleDeleteSession}
          />
          <DeleteConfirmation
            setModal={setModal}
            modal={modal === "delete-project"}
            data={modalData}
            onDeleteSuccess={handleDeleteProject}
            item={"project"}
            description="This can’t be undone later"
          />
        </React.Fragment>
      )}

      <GlobalHotKeys
        keyMap={keyMap}
        handlers={keyHandlers}
        allowChanges={true}
      ></GlobalHotKeys>

      <CreateWorkspaceModal
        isOpen={workspaceModalOpen}
        onClose={() => {
          setWorkspaceModalOpen(false);
          navigate("/home");
        }}
      />
    </div>
  );
};

// need to rename later when finalized
export default ProjectSession;
