import { useParams, useLocation } from "react-router-dom";
import CommentCard from "../../components/CommentCard/CommentCard";
import "./comment.scss";
import supabase from "../../infrastructure/Supabase";
import { SUPABASE_TABLE_NAME } from "../../infrastructure/Supabase/constants";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import asset_loader from "../../assets/images/icons/loader.svg";
import { useSelector } from "react-redux";
import { DEFAULT_AVATAR } from "../../constants";

const Comment = () => {
  const location = useLocation();

  // const comments = useSelector((state) => state.comment.comments);

  // console.log("comments", comments);

  const [projectComments, setProjectComments] = useState([]);
  const [projectCreator, setProjectCreator] = useState([]);
  const [userProject, setUserProject] = useState([]);
  const [commentReactions, setCommentReactions] = useState([]);

  const { projectId } = useParams();
  console.log("projectid", projectId);

  useEffect(() => {
    const fetchComments = async () => {
      const { data: comments } = await supabase
        .from(SUPABASE_TABLE_NAME.COMMENTS)
        .select("*")
        .eq("project_id", projectId);

      console.log("useeffect", comments);

      const { data: project } = await supabase
        .from(SUPABASE_TABLE_NAME.PROJECT)
        .select("*")
        .eq("id", projectId);

      const { data: user } = await supabase
        .from(SUPABASE_TABLE_NAME.PROFILES)
        .select("*")
        .eq("user_id", project[0].created_by);

      setProjectComments(comments);
      setUserProject(project);
      setProjectCreator(user);
    };

    const fetchReactions = async () => {
      const { data } = await supabase.from("reactions").select("*");
      await supabase
        .channel("reaction-all-channel")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "reactions" },
          (payload) => {
            console.log(payload);
            setCommentReactions((prev) => [...prev, payload.new]);
          }
        )
        .subscribe();

      setCommentReactions(data);
    };

    fetchReactions();
    fetchComments();
  }, [projectId]);

  // if (
  //   !location.state &&
  //   (projectComments.length === 0 ||
  //     userProject.length === 0 ||
  //     projectCreator.length === 0)
  // ) {
  //   return (
  //     <div className="loader">
  //       <img src={asset_loader} alt="Loading" />
  //     </div>
  //   );
  // }

  return (
    <div className="comment-container">
      <div className="comment-sidebar">
        <div className="comment-info">
          <img
            src={
              (location?.state
                ? location.state[0]?.user?.avatar
                : projectCreator[0]?.avatar) ?? DEFAULT_AVATAR
            }
            alt={`${
              location?.state
                ? location.state[0]?.user?.name
                : projectCreator[0]?.username
            } Avatar`}
            className="user-avatar"
          />
          <p className="user-name">
            {location?.state
              ? location.state[0]?.project?.title
              : userProject[0]?.title}
          </p>

          <p className="project-data">
            <span>
              By{" "}
              {location?.state
                ? location.state[0]?.user?.name
                : projectCreator[0]?.username}
            </span>
            , on{" "}
            {format(
              new Date(
                location?.state
                  ? location.state[0]?.createdAt.slice(0, 10)
                  : userProject[0]?.created_at.slice(0, 10) ?? Date.now()
              ),
              "LLL dd, yyyy"
            )}
          </p>
        </div>

        <div className="footer-badge">
          <a href="https://coil.so">
            <span>Created with Coil</span> - the simplest way to share feedback
          </a>
        </div>
      </div>

      <div className="comments-section">
        {location?.state
          ? location?.state.map((comment, index) => (
              <CommentCard
                key={comment.id}
                comment={comment}
                index={index}
                reactions={commentReactions.filter(
                  (item) => item.comment_id === comment.id
                )}
              />
            ))
          : projectComments?.map((comment, index) => (
              <CommentCard
                key={comment.id}
                comment={comment}
                index={index}
                reactions={commentReactions.filter(
                  (item) => item.comment_id === comment.id
                )}
              />
            ))}
      </div>

      <div className="footer-badge-mobile">
        <a href="https://coil.so">
          <span>Created with Coil</span> - the simplest way to share feedback
        </a>
      </div>
    </div>
  );
};

export default Comment;
