import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { useForm } from "react-hook-form";
import NotifierHelper from "../../application/common/NotifierHelper";

import asset_logo from "./../../assets/images/icons/lata-brand-logo.svg";
import asset_loader from "./../../assets/images/icons/loader.svg";
import asset_right_arrow from "./../../assets/images/icons/arrow-right-tail.svg";
import asset_upload from "./../../assets/images/icons/upload.svg";
import asset_placeholder from "./../../assets/images/icons/logo.svg";

import "./auth.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setUserData,
  setUserLoggedIn,
  setWorkSpaceData,
} from "../../store/reducers/userSlice";
import supabase from "../../infrastructure/Supabase";
import {
  SUPABASE_BUCKET_NAME,
  SUPABASE_TABLE_NAME,
} from "../../infrastructure/Supabase/constants";
import AnalyticsHelper from "../../application/common/AnalyticsHelper";

const WorkspaceSetup = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // const { register, handleSubmit, reset, formState: { isValid } } = useForm({ mode: "onChange" });
  const [isWaiting, setIsWaiting] = useState(false);
  const [workspaceData, setWorkspaceData] = useState({
    title: "My Workspace",
  });
  const [attachment, setAttachment] = useState({ file: asset_placeholder });

  useEffect(() => {
    const getUserData = async () => {
      const {
        data: { user },
        error,
      } = await supabase.auth.getUser();

      if (error) {
        return;
      }

      dispatch(setUserData(user));
      await supabase
        .from(SUPABASE_TABLE_NAME.PROFILES)
        .update({ is_verified: true })
        .eq("user_id", user.id);
    };

    if (location.hash.includes("error_code")) {
      navigate("/login");
      return;
    }

    if (Object.keys(userInfo).length === 0) {
      getUserData();
    }
  }, []);

  const makeWorkSpace = async (data) => {
    return await supabase
      .from(SUPABASE_TABLE_NAME.WORKSPACE)
      .insert({
        title: data.title,
        logo: data?.downloadURL,
        created_by: userInfo.id,
      })
      .select()
      .single();
  };

  const makeGroupInSegment = (data) => {
    // Track event in group event in segment
    AnalyticsHelper.segmentGroupEvent(data.id, {
      name: data.title,
      employees: 0,
      plan: "free",
      avatar: data?.downloadURL,
      createdAt: data.created_at,
      email: userInfo?.email,
    });
  };

  const updateUserWithWorkSpace = async (workSpace) => {
    // Add workspace id to role table
    await supabase
      .from(SUPABASE_TABLE_NAME.PROFILES)
      .update({ workspace_id: workSpace.id, role: "owner" })
      .eq("user_id", userInfo.id);
  };

  const makeTaskForWorkSpace = async (workSpace) => {
    const { data: groups, error } = await supabase
      .from(SUPABASE_TABLE_NAME.WORKFLOW_GROUPS)
      .select("*");

    if (error) return;

    const req = [];
    groups.forEach(async (group) => {
      req.push(
        supabase.from(SUPABASE_TABLE_NAME.WORKFLOW_GROUP_STATUS).insert({
          name: group.name,
          workflow_group_id: group.id,
          workspace_id: workSpace.id,
          position: 0,
        })
      );
    });

    await Promise.all(req);
  };

  const makeDefaultProject = async () => {
    const { data } = await makeWorkSpace({
      title: "My Workspace",
    });

    makeGroupInSegment(data);
    makeTaskForWorkSpace(data);

    updateUserWithWorkSpace(data);

    dispatch(setWorkSpaceData(data));
    navigate("/workspace-invite");
  };

  const handleSubmission = async () => {
    setIsWaiting(true);
    const { data: workSpace, error } = await makeWorkSpace(workspaceData);

    if (error) {
      NotifierHelper.notify("error", error.message);
      setIsWaiting(false);
      return;
    }

    makeGroupInSegment(workSpace);
    makeTaskForWorkSpace(workSpace);

    updateUserWithWorkSpace(workSpace);
    setIsWaiting(false);
    NotifierHelper.notify("info", "Workspace created!");
    dispatch(setWorkSpaceData(workSpace));
    dispatch(setUserLoggedIn(true));
    navigate("/workspace-invite");
  };

  const handleChange = (e, item = null, itemIndex = null, tIndex = null) => {
    const { name, value } = e.target;
    setWorkspaceData({ ...workspaceData, [name]: value });
  };

  const fileUpload = async (event) => {
    if (event.target.files.length > 0) {
      let tempUserData = { ...workspaceData };
      let attachment = {
        fileName: event.target.files[0].name,
      };
      const fileType = event.target.files[0].type;
      if (fileType.match(/image\/*/) == null) {
        attachment.error = "File not supported.";
        await setAttachment(attachment);
        return;
      } else {
        attachment.error = "";
      }

      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        attachment.file = reader.result;
        setAttachment(attachment);
        // setPost({ ...post, featured_url: reader.result })
      };
      tempUserData.attachment = event.target.files[0];

      // Upload file to storage
      const { data, error } = await supabase.storage
        .from(SUPABASE_BUCKET_NAME.WORKSPACE_LOGO)
        .upload(
          `${userInfo.id}-workspace.${tempUserData.attachment.name}`,
          tempUserData.attachment,
          {
            cacheControl: "3600",
            upsert: true,
          }
        );

      if (error) {
        event.target.files = null;
        NotifierHelper.notify("info", error.message);
        setAttachment({ file: asset_placeholder });
        return;
      }

      // Get downloadURL from storage for same file
      const { data: downloadURL } = supabase.storage
        .from(SUPABASE_BUCKET_NAME.WORKSPACE_LOGO)
        .getPublicUrl(data.path);

      tempUserData.downloadURL = downloadURL?.publicUrl;
      await setWorkspaceData(tempUserData);
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="col-12 px-2 h-100">
        <div className="top-logo">
          {/* <img className="mikyal-logo" src={asset_logo} alt="Mikyal-Logo" /> */}
        </div>
        <div className="meta-container">
          <h1 className="title fw-thick fs-h3 my-5 pt-3">
            Setup your workspace
          </h1>

          {/* {invitedError &&
                        <div className="red">
                            We'd love to show you Mikyal soon.
                            To get on the waitlist, visit <a href="https://www.mikyal.com/" target="_blank" rel="noreferrer">mikyal.com</a>
                        </div>
                    } */}
        </div>

        <div className="auth-content">
          <form>
            <div className=" row mt-4">
              <div className="col-md-12 marginbot mb-5">
                <div className="mikyal-field-header">
                  <label className="form-field-label">
                    {" "}
                    Name your workspace{" "}
                  </label>
                </div>
                <input
                  type="text"
                  className="form-control mikyal-field"
                  placeholder="ACME Inc."
                  name="title"
                  value={workspaceData.title}
                  onChange={handleChange}
                />
              </div>

              <div className="col-md-12 marginbot mb-4">
                <div className="mikyal-field-header">
                  <label className="form-field-label"> Workspace Logo </label>
                </div>
                <div className="profile-image d-flex align-items-end">
                  <div
                    className="dp-container"
                    style={{ backgroundImage: `url('${attachment.file}')` }}
                  >
                    <div className="input-file-wrapper">
                      <label htmlFor="dp_upload" className="custom-file-upload">
                        <img src={asset_upload} alt="Loading" />
                      </label>
                      <input
                        id="dp_upload"
                        type="file"
                        onChange={fileUpload}
                        required
                      />
                    </div>
                  </div>
                  <div className="ms-3 constraint">
                    PNG, JPEG. Max size of 1mb
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                <button
                  className="mikyal-btn black-button ms-0"
                  onClick={() => handleSubmission()}
                  disabled={isWaiting}
                >
                  <div>Continue</div>
                  <div className="btn-icon position-relative">
                    <img
                      className={"icon ms-2 " + (isWaiting ? "invisible" : "")}
                      src={asset_right_arrow}
                      alt=""
                    />
                    {isWaiting && (
                      <div className="btn-loader text-center">
                        <img
                          className="loader-inside"
                          src={asset_loader}
                          alt="loader"
                          width="30"
                          height="30"
                        />
                      </div>
                    )}
                  </div>
                </button>
              </div>
            </div>

            <div className="row mt-2 mb-3">
              <div className="col-md-12 text-center">
                <div className="skip-operation">
                  <span
                    onClick={() => makeDefaultProject()}
                    className="skip-operation"
                  >
                    Skip for now
                  </span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceSetup;
