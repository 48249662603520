import { red } from "@material-ui/core/colors";
import React from "react";

export default function MenuIcon({ className, strokeClass }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.998 12.9966C19.5503 12.9966 19.998 12.5489 19.998 11.9966C19.998 11.4443 19.5503 10.9966 18.998 10.9966C18.4458 10.9966 17.998 11.4443 17.998 11.9966C17.998 12.5489 18.4458 12.9966 18.998 12.9966Z"
        className={"icon-stroke"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9961 12.9966C12.5484 12.9966 12.9961 12.5489 12.9961 11.9966C12.9961 11.4443 12.5484 10.9966 11.9961 10.9966C11.4438 10.9966 10.9961 11.4443 10.9961 11.9966C10.9961 12.5489 11.4438 12.9966 11.9961 12.9966Z"
        className={"icon-stroke"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={"icon-stroke"}
        d="M5 12.9966C5.55228 12.9966 6 12.5489 6 11.9966C6 11.4443 5.55228 10.9966 5 10.9966C4.44772 10.9966 4 11.4443 4 11.9966C4 12.5489 4.44772 12.9966 5 12.9966Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
