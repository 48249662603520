import React from "react";

function AttachmentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke="#4B4B4B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
        d="M14.293 7.367l-6.126 6.126a4.002 4.002 0 11-5.66-5.66l6.126-6.126a2.668 2.668 0 113.774 3.773l-6.134 6.127A1.334 1.334 0 014.387 9.72l5.66-5.653"
      ></path>
    </svg>
  );
}

export default AttachmentIcon;
