import React, { useRef } from "react";

import "./index.scss";
import { useOnClickOutside } from "./hooks";

const ContextMenu = ({ x, y, onClose, buttons }) => {
  const contextMenuRef = useRef(null);
  useOnClickOutside(contextMenuRef, onClose);

  const handleClicked = (e, button) => {
    e.stopPropagation();

    onClose();
    button.onClick();
  };
  return (
    <menu
      ref={contextMenuRef}
      className={`context-menu`}
      style={{
        top: `${y}px`,
        left: `${x}px`,
      }}
    >
      {buttons.map((button, index) => (
        <div
          role="button"
          key={index}
          onClick={(e) => handleClicked(e, button)}
          className={`context-menu-item ${
            button.text === "Delete" || button.text === "Log Out" ? "red" : ""
          }`}
        >
          <div>{button.icon}</div>
          <span>{button.text}</span>
        </div>
      ))}
    </menu>
  );
};

export default ContextMenu;
