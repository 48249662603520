import { Helmet } from "react-helmet";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalHotKeys } from "react-hotkeys";

import { ReactComponent as OrangeDot } from "../../assets/images/icons/orange-dot.svg";
import NotificationCard from "../../infrastructure/Common/Notifications/NotificationCard";
import "./index.scss";
import { setActiveCommentId } from "../../store/reducers/commentSlice";
import { orderBy } from "lodash";
import supabase from "../../infrastructure/Supabase";
import { SUPABASE_TABLE_NAME } from "../../infrastructure/Supabase/constants";
import PostPill from "../../infrastructure/Common/TaskView/UpdatePill/post";
import { AnimatePresence, motion } from "framer-motion";

const NotificationContainer = ({ heading, notifications }) => {
  return (
    <>
      {heading && (
        <>
          <p className="notification-heading d-flex flex-row align-items-center gap-1">
            {heading.toLowerCase() === "new" && <OrangeDot />}
            {heading.toLowerCase() === "new" ? notifications.length : null}
            {heading}
          </p>
          <hr className="notification-border" />
        </>
      )}
      <div className="d-flex flex-column">
        {notifications.map((notification) => (
          <NotificationCard key={notification.id} notification={notification} />
        ))}
      </div>
    </>
  );
};

export default function Updates() {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);
  const comments = useSelector((state) => state.comment.comments);
  const activeCommentId = useSelector((state) => state.comment.activeCommentId);
  const { id } = useSelector((state) => state.project.defaultProject);

  console.log(comments);

  const userComments = comments.filter((comment) => comment.project.id === id);

  const keyMap = {
    keyUp: "up",
    keyDown: "down",
    unselect: "esc",
  };

  const keyHandlers = {
    unselect: () => {
      dispatch(setActiveCommentId(""));
    },
    keyUp: () => {
      handleArrowKey("up");
    },
    keyDown: () => {
      handleArrowKey("down");
    },
  };

  const feeds = useMemo(() => {
    return orderBy(comments, "createdAt", "desc");
  }, [comments]);

  // If user clicks on any comment then it will seen
  const handleSeenCommentForUser = async (comment) => {
    const updatedComment = {
      ...comment,
      seen_user_ids: comment?.seen_user_ids?.length
        ? [...comment.seen_user_ids, userInfo.id]
        : [userInfo.id],
    };
    await supabase
      .from(SUPABASE_TABLE_NAME.COMMENTS)
      .update({
        seen_user_ids: updatedComment?.seen_user_ids,
      })
      .eq("id", updatedComment.id);
  };

  // Handle seen comment
  useEffect(() => {
    if (activeCommentId) {
      const comment = comments.find(
        (comment) => comment.id === activeCommentId
      );
      // Only that comments are unseen which not created by current user and the userId is not present in seen_user_ids array.
      if (
        !comment.seen_user_ids.includes(userInfo.id) &&
        comment.created_by !== userInfo.id
      ) {
        handleSeenCommentForUser(comment);
      }
    }
  }, [activeCommentId]);

  // Handle Up and Down arrow key
  const handleArrowKey = (key) => {
    const sortedNotifications = orderBy(comments, "createdAt", "desc");

    if (sortedNotifications.length === 0) return;

    if (key === "up") {
      if (activeCommentId) {
        const activeIndex = sortedNotifications.findIndex(
          (noti) => noti.id === activeCommentId
        );
        dispatch(
          setActiveCommentId(
            sortedNotifications[
              activeIndex === 0
                ? sortedNotifications.length - 1
                : activeIndex - 1
            ]?.id
          )
        );
      } else {
        dispatch(
          setActiveCommentId(
            sortedNotifications[sortedNotifications.length - 1]?.id
          )
        );
      }
    } else {
      if (activeCommentId) {
        const activeIndex = sortedNotifications.findIndex(
          (noti) => noti.id === activeCommentId
        );
        dispatch(
          setActiveCommentId(
            sortedNotifications[
              activeIndex === sortedNotifications.length + 1
                ? 0
                : activeIndex + 1
            ]?.id
          )
        );
      } else {
        dispatch(setActiveCommentId(sortedNotifications[0]?.id));
      }
    }
  };

  return (
    <div className="update-container container-fluid">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Feed</title>
        <link rel="canonical" href="http://feed.lata.so/feed" />
      </Helmet>
      <div className="notification-container mx-auto">
        {/* <UpdatePill className="mb-4" /> */}
        <PostPill />
        <>
          {/* Newly created comments */}
          {comments.length > 0 ? (
            <NotificationContainer notifications={feeds} />
          ) : null}

          <AnimatePresence>
            {userComments.length === 0 && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                exit={{ opacity: 0, y: -50 }}
                className="empty-state d-flex flex-column align-items-center gap-2"
              >
                <span className="text-center">
                  Great job! You're all caught up.
                </span>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      </div>

      <GlobalHotKeys
        keyMap={keyMap}
        handlers={keyHandlers}
        allowChanges={true}
      />
    </div>
  );
}
