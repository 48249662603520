import asset_right_arrow from "./../../assets/images/icons/arrow-right-tail.svg";
import asset_logo from "./../../assets/images/icons/lata-brand-logo.svg";
import { useNavigate } from "react-router-dom";
import supabase from "../../infrastructure/Supabase";
import { useDispatch } from "react-redux";
import { resetUserState } from "../../store/reducers/userSlice";
import { resetSessionState } from "../../store/reducers/sessionSlice";
import { resetProjectState } from "../../store/reducers/projectSlice";
import { resetMemberState } from "../../store/reducers/membersSlice";
import { setDropDown } from "../../store/reducers/dropDownSlice";
import NotifierHelper from "../../application/common/NotifierHelper";

export default function NoWorkSpace() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmission = () => {
    navigate("/workspace-setup");
  };

  const logout = async () => {
    await supabase.auth.signOut();

    supabase.removeAllChannels();

    dispatch(resetUserState());
    dispatch(resetSessionState());
    dispatch(resetProjectState());
    dispatch(setDropDown({}));
    dispatch(resetMemberState());
    window.analytics.reset();
    NotifierHelper.notify("logout", "Logout successfully!");
    localStorage.removeItem("auth_token");
    navigate("/login");
  };

  return (
    <div className="vh-100 d-flex flex-column justify-content-start align-items-center">
      <div className="auth-wrapper" style={{ width: "fit-content" }}>
        <div className="auth-content">
          <div className="top-logo mb-3 pb-3">
            {/* <img className="mikyal-logo" src={asset_logo} alt="Mikyal-Logo" /> */}
          </div>
          <div className="meta-container pb-1">
            <h1 className="title fw-thick fs-h3 text-start">
              You don't have access to view this page
            </h1>
          </div>

          <div className="d-flex flex-row align-items-center gap-2 text-start dim-mlp">
            Create your own Lata workspace
          </div>

          <div className="d-flex flex-column align-items-center justify-content-center mt-5">
            <button
              className="mikyal-btn d-flex  black-button ms-0"
              onClick={() => handleSubmission()}
            >
              <div>Continue</div>
              <div className="btn-icon position-relative">
                <img className={"icon ms-2 "} src={asset_right_arrow} alt="" />
              </div>
            </button>
            <span className="dim-mlp fs-md mt-2" onClick={() => logout()}>
              Login to another account
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
