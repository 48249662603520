import "./index.scss";
import { getTimeAgo } from "../../../../application/common/helper";
import React, { useRef } from "react";
import ReplyInput from "./replyInput";
import Replies from "../../Replies";
import { Mention, MentionsInput } from "react-mentions";
import { imageDetector } from "../../../../application/utils/imageDetector";
import { MenuIcon } from "../../../../assets/AjxIcons";
import FileContainer from "../../FileContainer";
import { Emoji } from "emoji-picker-react";
import supabase from "../../../Supabase";
import { SUPABASE_TABLE_NAME } from "../../../Supabase/constants";
import { useState } from "react";
import DeleteConfirmation from "../../DeleteConfirmation";

const defaultAvatar = "https://i.ibb.co/JFR6LyW/user.png";

export default function NotificationCard({ notification }) {
  const notificationRef = useRef();
  const [openModal, setOpenModal] = useState(false);

  const handleDeleteComment = async () => {
    const { error } = await supabase
      .from(SUPABASE_TABLE_NAME.COMMENTS)
      .delete()
      .eq("id", notification.id);

    if (error) throw new Error(error);
  };

  const handleDeleteTask = async () => {
    await handleDeleteComment();

    setOpenModal(false);
  };

  return (
    <div
      className={"notification-card"}
      tabIndex={notification.id}
      ref={notificationRef}
    >
      <div className="d-flex flex-row align-items-center justify-content-between">
        <div className="user-and-time d-flex align-items-center">
          <img
            src={notification.user?.avatar || defaultAvatar}
            alt="User Profile"
            className="profile-img"
          />
          <h6>
            <span className="user-name">{notification.user.name}</span>
            <span>In</span>
            <div className="project">
              <Emoji
                unified={notification?.project?.emoji || "1f31f"}
                size={16}
              />
              <span>{notification?.project?.title}</span>
            </div>
            <span>{getTimeAgo(notification.createdAt)}</span>
          </h6>
        </div>
        <div className="popper">
          <div
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <MenuIcon className={"icon"} />
          </div>
          <div
            className="dd-data sidenav-dd dropdown-menu dropstart"
            aria-labelledby="dropdownMenuButton1"
          >
            <div
              className="dd-item red"
              role="button"
              onClick={() => setOpenModal(true)}
            >
              <span className="me-2">{/* <Logout /> */}</span>
              Delete
            </div>
          </div>
        </div>
      </div>
      <div className="post-content-container">
        <div className="notification">
          <MentionsInput
            className={`${!notification.id ? "not-active" : "pill-input"}`}
            disabled
            value={notification.body}
          >
            <Mention
              displayTransform={(_, display) => `@${display}`}
              markup="@[__display__](user:__id__)"
              className="mention"
            />
          </MentionsInput>

          {notification.file_link && imageDetector(notification.file_link) ? (
            <div className="image-container">
              <img
                className="post-image"
                src={notification.file_link}
                alt="Loading..."
              />
            </div>
          ) : notification.file_link ? (
            <FileContainer
              fileLink={notification.file_link}
              className="mt-0 border-0"
              fileName={notification.file_link
                ?.split("comment-files/")[1]
                .split("reply.")}
              isDownload
            />
          ) : null}
        </div>
        <div>
          {notification?.replies && notification?.replies?.length > 0 && (
            <Replies
              replies={notification?.replies}
              replyCount={notification?.reply_count}
              commentId={notification.id}
              workSpaceId={notification.workspace_id}
            />
          )}
          <ReplyInput notification={notification} placeholder="Reply..." />
        </div>

        {openModal && (
          <DeleteConfirmation
            setModal={setOpenModal}
            modal={openModal}
            data={{}}
            onDeleteSuccess={handleDeleteTask}
            modalLoader={false}
            item={"feed"}
            description="This can’t be undone later"
          />
        )}
      </div>
    </div>
  );
}
