const ArrowDown = ({ styles }) => {
  return (
    <svg
      width="8"
      height="10"
      viewBox="0 0 8 10"
      xmlns="http://www.w3.org/2000/svg"
      className={styles}
    >
      <path
        d="M0 5.40909L0.758523 4.64205L3.42188 7.3054V0.615057H4.52983V7.3054L7.18892 4.64205L7.9517 5.40909L3.97585 9.38494L0 5.40909Z"
        fill="black"
      />
    </svg>
  );
};

export default ArrowDown;
