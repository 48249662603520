import { useEffect, useState } from "react";

const CommentButton = ({ item, handleEmojiSubmit }) => {
  const [reaction, setReaction] = useState(null);

  useEffect(() => {
    setReaction(item);
  }, [item]);

  return (
    <button
      key={Math.random()}
      className="emoji-group"
      onClick={() => handleEmojiSubmit(reaction?.emoji)}
    >
      <img
        src={`https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/${reaction?.emoji}.png`}
        alt="emoji"
        className="emoji-image"
      />
      <p className="emoji-count">{reaction?.count}</p>
    </button>
  );
};

export default CommentButton;
