import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NotifierHelper from "../../application/common/NotifierHelper";

import asset_logo from "./../../assets/images/icons/lata-brand-logo.svg";
import asset_loader from "./../../assets/images/icons/loader.svg";
import asset_right_arrow from "./../../assets/images/icons/arrow-right-tail.svg";
import { ReactComponent as Dismiss } from "./../../assets/images/icons-mlp/x-dismiss.svg";

import "./auth.scss";
import { createInvite } from "../../infrastructure/ApiServices/InvitesApi";
import { useSelector } from "react-redux";

const WorkspaceInvites = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const workSpaceInfo = useSelector((state) => state.user.workSpaceInfo);
  const navigate = useNavigate();

  const [isWaiting, setIsWaiting] = useState(false);
  const [invites, setInvites] = useState([{ email: "" }]);

  const handleSubmission = () => {
    setIsWaiting(true);

    invites.forEach(async (invite) => {
      await createInvite(invite.email, "member", workSpaceInfo, userInfo.id);
    });

    setIsWaiting(false);
    NotifierHelper.notify("info", "User invited!");
    navigate("/home");
  };

  const handleChange = (e, index = null) => {
    const { name, value, checked } = e.target;
    let inv = [...invites];
    inv[index][name] = value;

    setInvites(inv);
  };

  const handleMultiple = (type, index = null) => {
    let inv = [...invites];
    if (type === "add") {
      inv.push({ email: "" });
    }
    if (type === "remove") {
      inv.splice(index, 1);
    }

    setInvites(inv);
  };

  return (
    <div className="auth-wrapper">
      <div className="col-12 px-2 h-100">
        <div className="top-logo">
          {/* <img className="mikyal-logo" src={asset_logo} alt="Mikyal-Logo" /> */}
        </div>
        <div className="meta-container">
          <h1 className="title fw-thick fs-h3 my-5 pt-3">Invite your team</h1>

          {/* {invitedError &&
                        <div className="red">
                            We'd love to show you Mikyal soon.
                            To get on the waitlist, visit <a href="https://www.mikyal.com/" target="_blank" rel="noreferrer">mikyal.com</a>
                        </div>
                    } */}
        </div>

        <div className="auth-content">
          <form>
            <div className=" row mt-4">
              {invites.length &&
                invites.map((inv, index) => (
                  <div
                    className="col-md-12 marginbot mb-3 position-relative"
                    key={index}
                  >
                    <div className="mikyal-field-header">
                      <label className="form-field-label">
                        {" "}
                        Email Address{" "}
                      </label>
                    </div>
                    <input
                      type="text"
                      className="form-control mikyal-field"
                      placeholder="example@email.com"
                      name="email"
                      // defaultValue={userData.email}
                      onChange={(e) => handleChange(e, index)}
                    />
                    {invites.length > 1 && (
                      <div
                        className="dismiss-invitee"
                        onClick={() => handleMultiple("remove", index)}
                      >
                        <Dismiss />
                      </div>
                    )}
                  </div>
                ))}

              <div className="col-md-12 marginbot mb-4">
                <div className="mikyal-field-header">
                  <label
                    className="form-field-label pointer"
                    onClick={() => handleMultiple("add")}
                  >
                    {" "}
                    + Invite another team member{" "}
                  </label>
                  {/* {errorMessage && <div className="text-end red small">{errorMessage}</div>} */}
                </div>

                {/* <div className="profile-image d-flex align-items-end">
                                    <div className="dp-container" style={{ backgroundImage: `url('${attachment.file}')` }}>
                                        <div className="input-file-wrapper">
                                            <label htmlFor="dp_upload" className="custom-file-upload">
                                                <img src={asset_upload} alt="Loading" />
                                            </label>
                                            <input
                                                id="dp_upload"
                                                type="file"
                                                onChange={fileUpload}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="ms-3 constraint">PNG, JPEG. Max size of 1mb</div>
                                </div> */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                <button
                  className="mikyal-btn black-button ms-0"
                  onClick={() => handleSubmission()}
                  disabled={isWaiting}
                >
                  <div>Send Invites</div>
                  <div className="btn-icon position-relative">
                    <img
                      className={"icon ms-2 " + (isWaiting ? "invisible" : "")}
                      src={asset_right_arrow}
                      alt=""
                    />
                    {isWaiting && (
                      <div className="btn-loader text-center">
                        <img
                          className="loader-inside"
                          src={asset_loader}
                          alt="loader"
                          width="30"
                          height="30"
                        />
                      </div>
                    )}
                  </div>
                </button>
              </div>
            </div>

            <div className="row mt-2 mb-3">
              <div className="col-md-12 text-center">
                <div className="skip-operation">
                  <Link to="/home" className="skip-operation">
                    Skip for now
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceInvites;
