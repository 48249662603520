import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import NotifierHelper from "../../application/common/NotifierHelper";
import supabase from "../../infrastructure/Supabase";
import { SUPABASE_TABLE_NAME } from "../../infrastructure/Supabase/constants";
import Loader from "../../infrastructure/Common/MasterLoader";
// import { setUserData, setWorkSpaceData } from "../../store/reducers/userSlice";

import asset_loader from "./../../assets/images/icons/loader.svg";
// import asset_placeholder from "./../../assets/images/icons/logo.svg";
import asset_right_arrow from "./../../assets/images/icons/arrow-right-tail.svg";
import asset_logo from "./../../assets/images/icons/lata-brand-logo.svg";
import workspace from "./../../assets/images/icons-mlp/workspace.svg";

export default function JoinWorkspace() {
  // const userInfo = useSelector((state) => state.user.userInfo);
  const [params, _] = useSearchParams();
  const [invitesDetail, setInvitesDetail] = useState(null);
  const navigate = useNavigate();
  const [isDataFetching, setIsDataFetching] = useState(true);
  const [isWaiting, setIsWaiting] = useState(false);
  const [invitedBy, setInvitedBy] = useState(null);
  // const dispatch = useDispatch();

  const getWorkspaceInfo = async () => {
    const inviteId = params.get("inviteId");
    const { data: invites, error: inviteError } = await supabase
      .from(SUPABASE_TABLE_NAME.INVITES)
      .select("*, workspace(*)")
      .eq("id", inviteId)
      .single();

    if (inviteError) {
      NotifierHelper.notify("info", inviteError.message);
      navigate("/home");
      return;
    }

    const { data: invitedBy } = await supabase
      .from(SUPABASE_TABLE_NAME.PROFILES)
      .select()
      .eq("user_id", invites.invited_by)
      .single();

    setInvitedBy(invitedBy);

    // // If user not same as invited user then send back to home.
    // if (invites.user_id !== user.id) {
    //   NotifierHelper.notify(
    //     "info",
    //     "Not have access to join workspace! Please check your email"
    //   );
    //   navigate("/home");
    //   return;
    // }

    // If user already joined workspace
    if (invites.is_joined) {
      NotifierHelper.notify("info", "Already joined workspace");
      navigate("/home");
      return;
    }

    setInvitesDetail(invites);
    setIsDataFetching(false);
  };

  // const checkIsUserLoggedIn = async () => {
  //   const {
  //     data: { user },
  //     error,
  //   } = await supabase.auth.getUser();

  //   if (error) {
  //     navigate("/login");
  //     return;
  //   }

  //   dispatch(setUserData(user));
  // };

  useEffect(() => {
    getWorkspaceInfo();
  }, []);

  const handleSubmission = async () => {
    const inviteId = params.get("inviteId");
    setIsWaiting(true);
    const {
      data: { user },
    } = await supabase.auth.getUser();

    if (user) {
      // If logged in user is not same as invited user then redirect back to home
      if (user.email !== invitesDetail?.email) {
        NotifierHelper.notify(
          "info",
          "You're not invited to be the part of this workspace"
        );
        navigate("/home");
        return;
      }
      // Else make entry of that user in the workspace
      const { data } = await supabase
        .from(SUPABASE_TABLE_NAME.PROFILES)
        .select("id")
        .eq("user_id", user.id)
        .single();
      const userObj = {
        user_id: user.id,
        email: user.email,
        role: invitesDetail.role,
        username: user.user_metadata.username,
        is_verified: true,
        workspace_id: invitesDetail.workspace_id,
      };
      if (data?.id) {
        await supabase
          .from(SUPABASE_TABLE_NAME.PROFILES)
          .update(userObj)
          .eq("id", data?.id);
      } else {
        await supabase.from(SUPABASE_TABLE_NAME.PROFILES).insert(userObj);
      }

      await supabase
        .from(SUPABASE_TABLE_NAME.INVITES)
        .update({
          is_joined: true,
          user_id: user.id,
          username: user.user_metadata.username,
        })
        .eq("id", inviteId);

      setIsWaiting(false);
      navigate(`/home`);
      return;
    }

    if (invitesDetail.user_id) {
      navigate(`/login?inviteId=${inviteId}`);
      return;
    }

    navigate(`/register?inviteId=${inviteId}`);
    // setIsWaiting(true);
    // const inviteId = params.get("inviteId");
    // // Add current user in profile with joined workspace id
    // const { error } = await supabase.from(SUPABASE_TABLE_NAME.PROFILES).insert({
    //   user_id: userInfo.id,
    //   email: userInfo.email,
    //   role: invitesDetail.role,
    //   username: userInfo.user_metadata.username,
    //   is_verified: true,
    //   workspace_id: invitesDetail.workspace_id,
    // });

    // if (error) {
    //   NotifierHelper.notify(
    //     "info",
    //     error?.message || "Something went wrong please try again"
    //   );
    //   return;
    // }

    // await supabase
    //   .from(SUPABASE_TABLE_NAME.INVITES)
    //   .update({
    //     is_joined: true,
    //     username: userInfo.user_metadata.username,
    //   })
    //   .eq("id", inviteId);

    // dispatch(
    //   setUserData({
    //     ...userInfo,
    //     type: invitesDetail.role,
    //   })
    // );
    // dispatch(setWorkSpaceData(invitesDetail.workspace));
    // setIsWaiting(false);
    // navigate("/home");
    // update joined flag in invites
  };

  // const handleLogout = async () => {
  //   await supabase.auth.signOut();
  //   navigate("/login")
  // }

  return (
    <div className="vh-100 d-flex flex-column justify-content-start align-items-center">
      {isDataFetching && <Loader />}
      {!isDataFetching && (
        <div className="auth-wrapper" style={{ width: "fit-content" }}>
          <div className="auth-content">
            <div className="top-logo mb-3 pb-3">
              {/* <img className="mikyal-logo" src={asset_logo} alt="Mikyal-Logo" /> */}
            </div>
            <div className="meta-container pb-1">
              <h1 className="title fw-thick fs-h3 text-start">
                Good to see you 👋
              </h1>
            </div>

            <div className="d-flex flex-row align-items-center gap-2 text-start dim-mlp">
              {invitedBy
                ? `${invitedBy.username} has invited to you join the`
                : "You have been invited to join the"}
              <div
                className="mlp-btn dim-mlp large no-wrap d-flex align-items-center justify-content-between"
                style={{ fontSize: "95%" }}
              >
                <img
                  src={invitesDetail.workspace.logo || workspace}
                  style={{
                    filter: "none",
                    marginRight: 5,
                    width: invitesDetail?.workspace?.logo ? 20 : 25,
                  }}
                  alt="workspace-logo"
                />{" "}
                {invitesDetail.workspace.title}
              </div>{" "}
              team
            </div>

            <div className="d-flex flex-column align-items-center justify-content-center mt-5">
              <button
                className="mikyal-btn d-flex  black-button ms-0"
                onClick={() => handleSubmission()}
                disabled={isWaiting}
              >
                <div>Accept Invite</div>
                <div className="btn-icon position-relative">
                  {!isWaiting && (
                    <img
                      className={"icon ms-2 "}
                      src={asset_right_arrow}
                      alt=""
                    />
                  )}
                  {isWaiting && (
                    <div
                      className="ms-2 btn-loader text-center"
                      style={{ position: "relative", top: 0, right: 0 }}
                    >
                      <img
                        className="loader-inside"
                        src={asset_loader}
                        alt="loader"
                        width="30"
                        height="30"
                      />
                    </div>
                  )}
                </div>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
